import React, { useState, useMemo, useEffect, useRef } from 'react';

import {
  useTable,
  useSortBy,
  useFilters,
  useFlexLayout,
  useRowSelect,
} from 'react-table';

import { getHiddenColumns } from '../utils';
import { STANDARD_CATEGORIES_LOOKUP } from '../../categories';

import { useCreativeAnalyticsGet } from '../../contexts';

import { useActionName, useCustomEvents, useGaEnabled } from '../../selectors';
import { useWindowSize } from '../hooks';
import { Row } from './Row';
import { useScrollIntoView } from '../../../../hooks/useScrollIntoView';
import LazyLoader from '../../../../components/LazyLoader';
import '../FrequencyTable.scss';
import {
  useCreativeAnalyticsStore,
  useViewSettings,
} from '../../../../selectors';
import { useFilteredColumns } from '../../hooks/useFilteredColumns';

const calculateMultiplier = (size, columns, period2) => {
  if (!period2 || !columns.includes('visuals')) return 2.2;

  if (columns.length === 2) {
    if (size.width < 1300) return 3.25;
    else if (size.width < 1550) return 2.75;
    else return 2.2;
  } else if (columns.length > 2) {
    if (size.width < 1300) return 3.5;
    else if (size.width < 1550) return 3.25;
    else if (size.width < 1650) return 3;
    else if (size.width < 1920) return 2.2;
    else return 2;
  }
  return 2.2;
};

export const Table = ({
  category_name,
  categoryData,
  categoryLabels,
  columns = [],
  isCompare,
}) => {
  const actionName = useActionName();
  const isROAS = actionName.includes('value_');
  const {
    category: highlightedCategory,
    isDirectMail,
    clientMetrics,
    filteredData,
    period2,
    isOrganicView,
    account_id,
    isEmailView,
    customScoringData,
    selectedTab,
  } = useCreativeAnalyticsGet();
  const { hiddenLabels } = useCreativeAnalyticsStore();
  const size = useWindowSize();
  const gaEnabled = useGaEnabled();
  const customEvents = useCustomEvents();
  const _ = require('lodash');
  const { additional_metrics, customIntegratedMetrics, isWisdomPanel } =
    useViewSettings();
  const cols = useFilteredColumns(isCompare, columns);

  const isSelected =
    category_name === highlightedCategory ||
    category_name === STANDARD_CATEGORIES_LOOKUP[highlightedCategory];
  const ref = useRef();
  useScrollIntoView(ref, isSelected, {
    behavior: 'instant',
    block: 'center',
  });

  const multiplier = calculateMultiplier(size, columns, period2);

  const memoed = useMemo(
    () => ({ cols, rows: categoryLabels }),
    [
      filteredData,
      clientMetrics,
      customEvents,
      columns,
      highlightedCategory,
      categoryLabels,
    ]
  );

  const hiddenCols = useMemo(
    () =>
      getHiddenColumns(
        columns,
        isROAS,
        isDirectMail,
        clientMetrics,
        gaEnabled,
        customEvents,
        additional_metrics,
        customIntegratedMetrics,
        isOrganicView,
        account_id,
        isEmailView,
        customScoringData,
        isWisdomPanel,
        selectedTab
      ),
    [
      filteredData,
      columns,
      isROAS,
      isDirectMail,
      clientMetrics,
      gaEnabled,
      customEvents,
      additional_metrics,
      customIntegratedMetrics,
      isOrganicView,
      account_id,
      isEmailView,
      customScoringData,
      isWisdomPanel,
      selectedTab,
    ]
  );

  const {
    getTableProps,
    getTableBodyProps,
    setHiddenColumns,
    rows,
    prepareRow,
    headerGroups,
  } = useTable(
    {
      columns: memoed.cols,
      data: memoed.rows,
      initialState: {
        hiddenColumns: [hiddenCols],
      },
    },
    useFilters,
    useSortBy,
    useFlexLayout,
    useRowSelect
  );

  useEffect(() => {
    setHiddenColumns(hiddenCols);
  }, [hiddenCols]);

  const compliance = ['No - Not Compliant', 'Yes - Compliant'];
  const labelsNames = categoryLabels.map(({ name }) => name);

  return (
    <div ref={ref}>
      <table
        {...getTableProps()}
        className={`frequency-table ${isSelected ? 'is-selected' : ''}`}
      >
        <thead className="frequency-table__header">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => {
                return (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    title={column.Header}
                    className={
                      column.isSorted
                        ? column.isSortedDesc
                          ? 'sorted-desc'
                          : 'sorted-asc'
                        : ''
                    }
                  >
                    <div className="table-header__text">
                      {column.render('Header')}
                    </div>

                    <span>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? ' 🔽'
                          : ' 🔼'
                        : ''}
                    </span>
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        {categoryLabels.length ? (
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);

              const isHidden = hiddenLabels?.some((x) => {
                return (
                  x.label === row.original.name &&
                  x.category === row.original.category
                );
              });
              const isSelected =
                row.original.category === highlightedCategory ||
                highlightedCategory ===
                  STANDARD_CATEGORIES_LOOKUP[row.original.category];

              if (isOrganicView && row.original.name === 'All New Visuals') {
                return null;
              }

              if (isHidden) return null;

              return (
                <Row
                  {...{
                    isSelected,
                    row,
                    isCompare,
                    filteredData,
                    multiplier,
                  }}
                />
              );
            })}
          </tbody>
        ) : (
          <div className="d-flex flex-column align-items-center justify-content-center mx-auto w-100 mt-5">
            <div className="no-data-face">
              <span></span>
              <span></span>
            </div>
            <h5 className="my-0 ml-3 mt-3">No data to display.</h5>
          </div>
        )}
      </table>
    </div>
  );
};
