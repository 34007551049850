import React from 'react';
import { VisualsLookupItem } from './types';
import { toCurrency, toPercent_2 } from '../../../utils/numbers';

const FORBIDDEN_TOP_LABELS = ['no primary message', 'no logo'];

// test

export const getSuggestions = (
  current: VisualsLookupItem,
  isROAS,
  categorySettings,
  data_type,
  fromCreativeStudio,
  bestPerforming,
  primary_metric
) => {
  let result = [];

  if (!current || !current.categories) return [];
  if (!current || !current.categories) return [];

  current.categories.forEach((category) => {
    const { multiple_labels } = category;
    const settings = categorySettings
      ? categorySettings.find((c) => c.category_name === category.category)
      : null;
    const enabled = settings ? settings.enabled.recommendations : false;
    let customDisplay = null;

    category.labels.forEach((label, labelIndex, labelArr) => {
      const hasTopLabel = !fromCreativeStudio
        ? labelArr[0].visuals.some((visual) => visual.id === current.id)
        : labelArr[0].visuals.some((visual) => visual.id === current.visual_id);
      const shouldAdd =
        enabled && !result.some((s) => s.category === category.category);

      const typeMetric = formatTypeMetric(data_type, primary_metric);
      const labelValue = label[typeMetric];
      const topValue = labelArr[0][typeMetric];
      const diff = getDiff(primary_metric, labelValue, topValue);
      const percentDiff = getPercentDiff(diff, labelValue, topValue);

      const highestBudget = category.labels.reduce((prev, curr) =>
        parseInt(prev.percent_budget) >= parseInt(curr.percent_budget)
          ? prev
          : curr
      );
      const highestBudgetIndex = category.labels.findIndex(
        (item) => item.percent_budget === highestBudget.percent_budget
      );
      const percentPosts = parseFloat(
        highestBudget.visuals.length / category.visuals.length
      ).toLocaleString(undefined, {
        style: 'percent',
        minimumFractionDigits: 0,
      });

      const isForbidden = FORBIDDEN_TOP_LABELS.includes(
        labelArr[0].name.toLowerCase()
      );

      const competitorDiff = getDiff(
        'roas',
        label.visuals.length,
        labelArr[0].visuals.length
      );

      const organicDiff = getDiff(
        'roas',
        label.likes_per_post,
        labelArr[0].likes_per_post
      );

      const competitorPercentDiff = getPercentDiff(
        competitorDiff,
        label.visuals.length,
        labelArr[0].visuals.length
      );

      const organicPercentDiff = getPercentDiff(
        organicDiff,
        label.likes_per_post,
        labelArr[0].likes_per_post
      );

      if (shouldAdd) {
        if (!bestPerforming) {
          if (
            !fromCreativeStudio
              ? label.visuals.some((visual) => visual.id === current.id)
              : current.labels && current.labels.includes(label.value)
          ) {
            if (labelIndex > 0 && !hasTopLabel && !isForbidden) {
              const current = label.name;
              const change = labelArr[0].name;

              if (
                category.category.toLowerCase() === 'primary message position'
              ) {
                const { changeText, currentText } = getPositionSuggestion(
                  change.toLowerCase(),
                  current.toLowerCase()
                );

                if (current.toLowerCase() === 'no primary message') {
                  customDisplay = (
                    <div className="suggestion__details">
                      <p>
                        Add a primary message to the visual and put it{' '}
                        <span style={{ fontWeight: 700 }}>{changeText}</span>.
                      </p>
                    </div>
                  );
                } else {
                  customDisplay = (
                    <div className="suggestion__details">
                      <p>
                        Move the primary message from the{' '}
                        <span style={{ fontWeight: 700 }}>{currentText}</span>{' '}
                        to the{' '}
                        <span style={{ fontWeight: 700 }}>{changeText}</span>.
                      </p>
                    </div>
                  );
                }
              }

              if (category.category.toLowerCase() === 'logo position') {
                const { changeText, currentText } = getPositionSuggestion(
                  change.toLowerCase(),
                  current.toLowerCase()
                );

                if (current.toLowerCase() === 'no logo') {
                  customDisplay = (
                    <div className="suggestion__details">
                      <p>
                        Add your logo to the visual and put it at the{' '}
                        <span style={{ fontWeight: 700 }}>{changeText}</span>.
                      </p>
                    </div>
                  );
                } else {
                  customDisplay = (
                    <div className="suggestion__details">
                      <p>
                        Move the logo from the{' '}
                        <span style={{ fontWeight: 700 }}>{currentText}</span>{' '}
                        to the{' '}
                        <span style={{ fontWeight: 700 }}>{changeText}</span>.
                      </p>
                    </div>
                  );
                }
              }

              result.push({
                category: category.category,
                current: label.name,
                change: labelArr[0].name,
                multiple_labels,
                percentDiff,
                customDisplay,
                competitorPercentDiff,
                organicPercentDiff,
              });
            }
          }
        } else {
          result.push({
            category: category.category,
            percent_budget: highestBudget.percent_budget,
            label: highestBudget.name,
            rank: highestBudgetIndex,
            total: category.labels.length,
            percentPosts,
          });
        }
      }
    });
  });

  return result;
};

export const sortReportCardCategories = (a, b) => {
  const categoryA = a.category;
  const categoryB = b.category;

  if (categoryA === 'Brand' && categoryB !== 'Brand') return -1;
  if (categoryB === 'Brand' && categoryA !== 'Brand') return 1;

  if (categoryA === 'Format' && categoryB !== 'Format') return -1;
  if (categoryB === 'Format' && categoryA !== 'Format') return 1;

  if (categoryA === 'Price' && categoryB !== 'Price') return -1;
  if (categoryB === 'Price' && categoryA !== 'Price') return 1;

  if (categoryA === 'Promo' && categoryB !== 'Promo') return -1;
  if (categoryB === 'Promo' && categoryA !== 'Promo') return 1;
};

export const getDiff = (selected_metric, current, top) => {
  const sortedDescending = ['roas', 'ctr'].includes(selected_metric);

  if (sortedDescending) {
    return +Number(Number(top) - Number(current));
  } else {
    return +Number(Number(current) - Number(top));
  }
};

const getPercentDiff = (diff, current, top) => {
  return (Math.abs(diff) / ((Number(current) + Number(top)) / 2)) * 100;
};

export const getPositionSuggestion = (change, current) => {
  let changeText = null;
  let currentText = null;

  if (change && typeof change === 'string') {
    if (change.includes('bottom')) {
      changeText = 'bottom';
    } else if (change.includes('middle')) {
      changeText = 'middle';
    } else if (change.includes('top')) {
      changeText = 'top';
    } else if (change.includes('no')) {
      changeText = 'N/A';
    }
  }

  if (current && typeof current === 'string') {
    if (current.includes('bottom')) {
      currentText = 'bottom';
    } else if (current.includes('middle')) {
      currentText = 'middle';
    } else if (current.includes('top')) {
      currentText = 'top';
    } else if (current.includes('no')) {
      currentText = 'N/A';
    }
  }

  return {
    changeText,
    currentText,
  };
};

export const sortReportCardLabels = (
  isROAS,
  isDirectMail,
  reportCardDataType,
  viewName,
  reportCardMetric
) => {
  if (
    isDirectMail &&
    viewName &&
    viewName.toLowerCase().includes('competitor')
  ) {
    return (a, b) => {
      if (a.visual_ids.length === 0) return 1;
      if (b.visual_ids.length === 0) return -1;
      return b.visual_ids.length - a.visual_ids.length;
    };
  }

  if (isDirectMail && viewName && viewName.toLowerCase().includes('organic')) {
    return (a, b) => {
      if (a[`${reportCardMetric} per post`] > b[`${reportCardMetric} per post`])
        return -1;
      if (a[`${reportCardMetric} per post`] < b[`${reportCardMetric} per post`])
        return 1;
      return 0;
    };
  }

  return (a, b) => {
    const typeMetric = formatTypeMetric(reportCardDataType, reportCardMetric);
    const oppA = a.total_results === 0 && a.spend === 0;
    const oppB = b.total_results === 0 && b.spend === 0;

    if (oppA) return 1;
    if (oppB) return -1;

    const a_float = a[typeMetric];
    const b_float = b[typeMetric];

    const sortDesc =
      ['roas', 'ctr'].includes(reportCardMetric) ||
      (isROAS && reportCardMetric === 'cpa');

    return sortDesc
      ? b_float - a_float
      : (a_float === 0) - (b_float === 0) ||
          !Number.isFinite(a_float) - !Number.isFinite(b_float) ||
          -(a_float < b_float);
  };
};

export const getSuggestionTooltip = (
  suggestion,
  isROAS,
  isCompetitorView,
  isOrganicView
) => {
  if (isCompetitorView) {
    return (
      <span>
        Currently, there is a{' '}
        <span className="diff">
          {`${
            suggestion.competitorPercentDiff < 1 ? 'less than ' : ''
          }${Math.round(suggestion.competitorPercentDiff)}`}
          %
        </span>{' '}
        difference between the current value and the top value.
      </span>
    );
  }

  if (isOrganicView) {
    return (
      <span>
        Currently, there is a{' '}
        <span className="diff">
          {`${
            suggestion.organicPercentDiff < 1 ? 'less than ' : ''
          }${Math.round(suggestion.organicPercentDiff)}`}
          %
        </span>{' '}
        difference between the current value and the top value.
      </span>
    );
  }

  return (
    <>
      <span className="subtle">Currently, there is a </span>
      <span>
        {`${suggestion.percentDiff < 1 ? 'less than ' : ''}${Math.round(
          suggestion.percentDiff
        )}`}
        %
      </span>{' '}
      <span className="subtle">
        difference between the current value and the top value.
      </span>
    </>
  );
};

export const formatMetric = (selected_metric, isROAS, isGoHealth, type) => {
  if (selected_metric.length < 5) {
    if (selected_metric === 'cpa') {
      if (isROAS) {
        return 'ROAS';
      }
      return 'CPA';
    }
    return selected_metric.toUpperCase();
  }

  if (isGoHealth && selected_metric.includes('OppAttempted'))
    return `${capitalizeFirst(type)} ${selected_metric.slice(3)}`;
  return capitalizeFirst(selected_metric);
};

export const calculateMetric = (metric, data) => {
  switch (metric) {
    case 'cpm':
      return data.impressions > 0
        ? toCurrency((data.spend / data.impressions) * 1000)
        : null;
    case 'cpc':
      return data.clicks > 0 ? toCurrency(data.spend / data.clicks) : null;
    case 'ctr':
      return data.impressions > 0
        ? toPercent_2(data.clicks / data.impressions)
        : null;
    case 'cpik':
      return data.impressions > 0
        ? ((data.leads / data.impressions) * 100000).toFixed(2)
        : null;
    default:
      return '-';
  }
};

export const capitalizeFirst = (str) => {
  return str.replace(/\b\w/g, (l) => l.toUpperCase());
};

export const formatTypeMetric = (type, metric, isDuff, isGoHealth) => {
  if (isDuff) return metric;

  if (metric.length > 4) {
    // this is to format metrics such as 'Video plays'
    if (isDuff) return capitalizeFirst(metric);
    if (isGoHealth && metric.includes('OppAttempted'))
      return `${capitalizeFirst(type)} ${metric.slice(3)}`;
    return `${capitalizeFirst(type)} ${metric}`;
  }
  return `${type}_${metric}`;
};

export const isStaticReportCard = () => {
  return window.location.href.includes('static-report-card');
};
