import React, { forwardRef } from 'react';
import Tooltip from '../../../../../components/Tooltip/Tooltip';

type DrawerSectionProps = {
  show?: boolean;
  title?: string;
  content?: React.ReactNode;
  icon?: React.ReactNode;
  titleTooltip?: React.ReactNode | string;
  iconTooltip?: React.ReactNode | string;
  style?: React.CSSProperties;
  className?: string;
  onClick?: () => void;
  ref?: React.RefObject<HTMLDivElement>;
  children?: React.ReactChildren; // custom render
};

const DrawerSection = forwardRef<HTMLDivElement, DrawerSectionProps>(
  (
    {
      show = true,
      title = '',
      content,
      icon,
      titleTooltip = null,
      iconTooltip = null,
      style = {},
      className = '',
      onClick,
      children,
    },
    ref = null
  ) => {
    if (!show) return null;

    if (children) {
      // custom override
      return (
        <div
          ref={ref}
          className={`drawer-section ${className}`}
          style={style}
          onClick={onClick}
        >
          {children}
        </div>
      );
    }

    const updatedStyle = {
      ...style,
      cursor: !!onClick ? 'pointer' : 'auto',
    };

    return (
      <div
        className={`drawer-section ${className}`}
        style={updatedStyle}
        onClick={onClick}
        ref={ref}
      >
        <div className="d-flex justify-content-between">
          {title && (
            <div className="drawer-section__title">
              {title}{' '}
              {titleTooltip && (
                <Tooltip
                  shouldShow={title && titleTooltip}
                  content={titleTooltip}
                >
                  <i className="drawer-section__info-icon fa fa-circle-info" />
                </Tooltip>
              )}
            </div>
          )}
          {title && <div className="drawer-section__title"></div>}
          {icon && (
            <Tooltip shouldShow={icon && iconTooltip} content={iconTooltip}>
              <div className="drawer-section__icon">{icon}</div>
            </Tooltip>
          )}
        </div>

        {content && <div className="drawer-section__content">{content}</div>}
      </div>
    );
  }
);

export default DrawerSection;
