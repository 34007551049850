import React from 'react';
import Button from '../../../components/Button';
import { DARWIN_NAVY } from '../../../constants';
import { useCreativeAnalyticsSet } from '../contexts';
import Tooltip from '../../../components/Tooltip';

const RulesManagerToggle = () => {
  const { setShowRulesManager } = useCreativeAnalyticsSet();

  return (
    <div>
      <Tooltip content="Rules Manager">
        <div className="d-inline-flex">
          <Button
            appearance="raised"
            onClick={() => setShowRulesManager(true)}
            style={{ padding: 0, height: 32, width: 32 }}
          >
            <svg
              width="15"
              height="18"
              viewBox="0 0 15 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.01163 14.9526V2.95264C1.01163 2.4222 1.22234 1.9135 1.59741 1.53842C1.97249 1.16335 2.48119 0.952637 3.01163 0.952637H13.0116C13.2238 0.952637 13.4273 1.03692 13.5773 1.18695C13.7273 1.33698 13.8116 1.54046 13.8116 1.75264V16.1526C13.8116 16.3648 13.7273 16.5683 13.5773 16.7183C13.4273 16.8684 13.2238 16.9526 13.0116 16.9526H3.01163C2.48119 16.9526 1.97249 16.7419 1.59741 16.3669C1.22234 15.9918 1.01163 15.4831 1.01163 14.9526ZM1.01163 14.9526C1.01163 14.4222 1.22234 13.9135 1.59741 13.5384C1.97249 13.1634 2.48119 12.9526 3.01163 12.9526H13.8116"
                stroke={DARWIN_NAVY}
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M5.01163 6.95254L6.61163 8.55254L9.81163 5.35254"
                stroke={DARWIN_NAVY}
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </Button>
        </div>
      </Tooltip>
    </div>
  );
};

export default RulesManagerToggle;
