import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useInputState } from './hooks';

import { LabelObject } from '../../../types';
import { useCustomCategories } from '../../hooks';
import { EASY_AI, MULTIPLE_LABELS, MUTUALLY_EXCLUSIVE } from '../../constants';

import {
  useDarwinClientId,
  useDates,
  usePlatform,
  useViewId,
} from '../../selectors';
import { useCustomCategoriesApi } from '../../api';
import {
  makeCreateMmCategoryPayload,
  makeDeleteMmCategoryPayload,
  makeEditMmCategoryPayload,
  makeFetchPayload,
} from '../../payloads';
import { useAnalyticsParams, useDatePeriods } from '../../../hooks';

import Setup from './Setup';
import Editor from './Editor';
import {
  backfillAnnotatedVisuals,
  fillMissingFrames,
  makeVisualsObject,
  reformatAnnotatedVisuals,
} from './utils';
import {
  useCreativeAnalyticsGet,
  useCreativeAnalyticsSet,
} from '../../../contexts';
import { useVisuals } from '../../../selectors';
import { MmDeleteSuccess, MmSaveSuccess, useVertexApi } from './api';
import Button from '../../../../../components/Button';
import { useNotifications } from '../../../../../components/Notification/useNotifications';
import { toast } from 'react-toastify';
import { CloseIcon } from '../../../../../components/Icons';
import { DARWIN_NAVY } from '../../../../../constants';
import useConfirm from '../../../../../hooks/useConfirm';

const getExistingAnnotatedVisuals = (existing) => {
  if (!existing) return null;
  if (!existing?.annotated_visuals) return existing;

  if (existing.mutually_exclusive) {
    return existing.annotated_visuals;
  }

  return reformatAnnotatedVisuals(existing.annotated_visuals);
};

const AIDriven = ({
  stage,
  prev,
  next,
  dropdownOptions,
  category: { category: category_name, category_id, easy_ai = false },
  fetchPerformanceData,
  account_id,
}) => {
  const vertexApi = useVertexApi();
  const [input, handleInput, removeLabel, labelsHaveChanged, setFields] =
    useInputState();
  const [existing, setExisting] = useState(null);
  const [existingNames, setExistingNames] = useState({
    categories: [],
    labels: [],
  });
  const [annotatedVisuals, setAnnotatedVisuals] = useState<{}>(
    existing ? getExistingAnnotatedVisuals(existing) : {}
  );
  const [predictedVisuals, setPredictedVisuals] = useState<{}>(
    existing ? existing.predicted_visuals : {}
  );
  const ref = useRef();
  const api: { get: {}; post: {} } = useCustomCategoriesApi();
  const darwin_client_id = useDarwinClientId();
  const view_id = useViewId();
  const platform = usePlatform();
  const isNew = category_name === 'custom';
  const [initialSelected, setInitialSelected] = useState({}); // existing predicted + annotated visuals
  const [visualModeChanged, setVisualModeChanged] = useState(false);
  const [{ period1, period2 }] = useAnalyticsParams();
  const [period1Start, period1End, period2Start, period2End] = useDatePeriods(
    period1,
    period2
  );
  const allVisualsList = useVisuals();
  const { setEditCategory, setEditCategoryVisual } = useCreativeAnalyticsSet();
  const notifications = useNotifications();
  const [ConfirmDialog, confirm] = useConfirm('Delete this category?');

  const visualsData: {} = makeVisualsObject(allVisualsList);

  const fetch = (category_name, category_id) => {
    api.get.request(
      makeFetchPayload({
        category_name,
        category_id,
        platform,
        darwin_client_id,
        view_id,
        period1Start,
        period1End,
      })
    );
  };

  useEffect(() => {
    if (isNew) {
      fetch('');
    } else {
      fetch(category_name, category_id);
    }
  }, [category_name]);

  useEffect(() => {
    if (existing) {
      const { annotated_visuals } = fillMissingFrames(existing, visualsData);

      if (input.type === MULTIPLE_LABELS) {
        setAnnotatedVisuals(reformatAnnotatedVisuals(annotated_visuals));
      } else {
        setAnnotatedVisuals(annotated_visuals ?? {});
      }

      setPredictedVisuals(existing.predicted_visuals ?? {});
    }
  }, [existing]);

  useEffect(() => {
    if (api.get.data) {
      const { data } = api.get;

      const selected = {
        ...data.annotated_visuals,
        ...data.predicted_visuals,
      };

      const lower = (arr) => arr.map((name) => name?.toLowerCase());

      setInitialSelected(selected);
      setExistingNames({
        categories: data.existing_category_names
          ? lower(data.existing_category_names)
          : [],
        labels: data.existing_labels ? lower(data.existing_labels) : [],
      });

      if (isNew) {
      } else {
        setExisting({ ...data });
        if (!easy_ai) next();
      }
    }
  }, [api.get.data]);

  useEffect(() => {
    if (existing) {
      let type = null;

      if (easy_ai) {
        type = EASY_AI;
      } else {
        if (existing.mutually_exclusive) {
          type = MUTUALLY_EXCLUSIVE;
        } else {
          type = MULTIPLE_LABELS;
        }
      }

      setFields({
        name: existing.category,
        type,
        description: existing.description,
      });

      if (existing.output_label_info) {
        Object.keys(existing.output_label_info).forEach((label) => {
          handleInput('labels', label);
          handleInput('initialLabels', label);
        });
      }
    }
  }, [existing, setFields, handleInput]);

  const createVertexCategory = () => {
    const payload = makeCreateMmCategoryPayload({
      account_id,
      prompt: input.description,
      category_name: input.name,
      darwin_visual_id: Object.keys(visualsData),
    });

    vertexApi.create.request(payload);
  };

  const editVertexCategory = () => {
    const payload = makeEditMmCategoryPayload({
      account_id,
      prompt: input.description,
      category_name: input.name,
      category_id,
      darwin_visual_id: Object.keys(visualsData),
    });

    vertexApi.edit.request(payload);
  };

  const deleteVertexCategory = async () => {
    const payload = makeDeleteMmCategoryPayload({
      account_id,
      category_id,
    });

    const conf = await confirm();

    if (conf) {
      vertexApi.delete.request(payload);
    }
  };

  useEffect(() => {
    if (vertexApi.create.data === undefined) {
      setEditCategory(null);
      toast(
        <MmSaveSuccess
          name={input.name}
          refresh={() => fetchPerformanceData(true)}
        />,
        {
          position: 'top-right',
          closeOnClick: false,
          draggable: true,
          progress: undefined,
          closeButton: <CloseIcon className="toast__close" />,
          style: {
            background: DARWIN_NAVY,
            padding: 0,
          },
          autoClose: false,
        }
      );
    }
  }, [vertexApi.create.data]);

  useEffect(() => {
    if (vertexApi.edit.data === undefined) {
      setEditCategory(null);
      toast(
        <MmSaveSuccess
          name={input.name}
          refresh={() => fetchPerformanceData(true)}
        />,
        {
          position: 'top-right',
          closeOnClick: false,
          draggable: true,
          progress: undefined,
          closeButton: <CloseIcon className="toast__close" />,
          style: {
            background: DARWIN_NAVY,
            padding: 0,
          },
          autoClose: false,
        }
      );
    }
  }, [vertexApi.edit.data]);

  useEffect(() => {
    if (vertexApi.delete.data) {
      setEditCategory(null);
      toast(<MmDeleteSuccess name={input.name} />, {
        position: 'top-right',
        closeOnClick: false,
        draggable: true,
        progress: undefined,
        closeButton: <CloseIcon className="toast__close" />,
        style: {
          background: DARWIN_NAVY,
          padding: 0,
        },
        autoClose: false,
      });
    }
  }, [vertexApi.delete.data]);

  useEffect(() => {
    if (vertexApi.create.error || vertexApi.edit.error) {
      notifications.add({
        title: 'Error',
        message: 'There was an error saving the category.',
      });
    }
  }, [vertexApi.create.error, vertexApi.edit.error]);

  const getLoadingState = () => {
    if (input.type === EASY_AI) {
      return (
        vertexApi.create.loading ||
        vertexApi.edit.loading ||
        vertexApi.delete.loading
      );
    }

    return api.get.loading || api.delete.loading;
  };
  const isLoading = getLoadingState();

  if (api.get.loading) {
    return (
      <div className="category-manager category-manager--loading">
        <p className="category-manager--loading__msg loading-text">
          Fetching category data
        </p>
      </div>
    );
  }

  if (api.get.error) {
    return (
      <div className="category-manager category-manager--loading">
        <p className="category-manager--loading__msg">
          Error: Darwin category not found
        </p>
      </div>
    );
  }

  return (
    <div ref={ref}>
      {stage === 1 && (
        <Setup
          {...{
            dropdownOptions,
            next,
            prev,
            input,
            handleInput,
            removeLabel,
            existing,
            existingNames,
            setVisualModeChanged,
            isNew,
            createVertexCategory,
            editVertexCategory,
            deleteVertexCategory,
            isLoading,
            easy_ai,
          }}
        />
      )}
      {stage === 2 && (
        <Editor
          {...{
            prev,
            existing,
            fetch,
            fetchPerformanceData,
            visualsData,
            annotatedVisuals,
            setAnnotatedVisuals,
            predictedVisuals,
            setPredictedVisuals,
            initialSelected,
            labelsHaveChanged,
            visualModeChanged,
            category_id,
          }}
          category={category_name}
          input={input}
          isSingle={false}
        />
      )}
      <ConfirmDialog />
    </div>
  );
};

export default AIDriven;
