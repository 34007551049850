import React, { useEffect, useState } from 'react';
import CreatableSelect from 'react-select/creatable';
import { SELECT_STYLES } from '../../../../../constants';
import { Callout } from '../../../../../components/Callout';
import { useCreativeAnalyticsGet } from '../../../contexts';
import { useSaveOverridesApi } from '../../api';
import Button from '../../../../../components/Button';
import { useNotifications } from '../../../../../components/Notification/useNotifications';
import { useCategoriesLookup } from '../../../selectors';

type OverridesProps = {};

/* 

 */

const mock = [
  { label: 'label', value: 'value' },
  { label: 'label', value: 'value2' },
  { label: 'label', value: 'value3' },
  { label: 'label', value: 'value4' },
];

type SelectOption = { label: string; value: string };

export const Overrides = ({
  selectedVisualId,
  category_id,
  category_name,
  inThisVisual,
}) => {
  const notifications = useNotifications();
  const saveOverridesApi = useSaveOverridesApi();
  const { account_id } = useCreativeAnalyticsGet();
  const categoriesLookup = useCategoriesLookup();
  const categoryMatch = categoriesLookup[category_name];

  const labelOpts: SelectOption[] =
    categoryMatch?.labels?.map(({ name, value }) => ({
      label: name,
      value,
    })) ?? [];

  const initialSelected: SelectOption[] =
    inThisVisual?.map(({ name, value }) => ({
      label: name,
      value,
    })) ?? [];

  const [selected, setSelected] = useState<SelectOption[]>(initialSelected[0]);

  useEffect(() => {
    setSelected(initialSelected);
  }, [selectedVisualId]);

  const handleSelect = (selection: SelectOption[]) => {
    setSelected(selection);
  };

  const handleCreate = (newOpt) => {
    setSelected({ label: newOpt, value: `MM_${category_name}__${newOpt}` });
  };

  const handleSave = () => {
    try {
      const assigned_labels = [selected].reduce((acc, curr) => {
        if (!(curr.value === initialSelected[0].value)) {
          acc[curr.value] = initialSelected[0].value;
        }

        return acc;
      }, {});

      const payload = {
        hash_id: selectedVisualId,
        category_id: category_id,
        account_id: account_id,
        assigned_labels,
      };

      saveOverridesApi.request(payload);
    } catch (e) {
      console.log(e);
      notifications.add({
        id: 'save-override-error',
        title: 'Error',
        message: 'There was a problem saving the overrides. Please try again.',
      });
    }
  };

  return (
    <div className="d-flex flex-column">
      <Callout showIcon={false} style={{ marginBottom: '1rem' }}>
        Select a label from the list or type to create a new label.
      </Callout>

      <CreatableSelect
        isClearable
        options={labelOpts}
        styles={SELECT_STYLES}
        placeholder="Select label or type to create new..."
        value={selected || null}
        isSearchable
        onChange={handleSelect}
        onCreateOption={handleCreate}
      />

      <Button
        className="align-self-end mt-3"
        onClick={handleSave}
        loading={saveOverridesApi.loading}
      >
        Save
      </Button>
    </div>
  );
};
