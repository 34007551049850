import { useEffect } from 'react';
import { usePostRequest } from '../../../components/Hooks/usePostRequest';
import { useNotifications } from '../../../components/Notification/useNotifications';

export const useSaveOverridesApi = () => {
  const notifications = useNotifications();
  const saveApi = usePostRequest(
    ['api', 'creative-analytics', 'custom-categories', 'annotate-easy-ai'].join(
      '/'
    )
  );

  useEffect(() => {
    if (saveApi.loading) {
      notifications.add({
        id: 'save-override',
        title: 'Saving changes',
        message: 'Please wait',
        showEllipses: true,
      });
    } else {
      notifications.remove('save-override');
    }
  }, [saveApi.loading]);

  useEffect(() => {
    if (saveApi.data || saveApi.data === undefined) {
      notifications.add({
        id: 'save-override-success',
        title: 'Success!',
        message: 'Overrides saved. Run a hard refresh to see results.',
      });
    }
  }, [saveApi.data]);

  return saveApi;
};
