import React from 'react';
import './styles.scss';

export const Callout = ({ style = {}, showIcon = true, children }) => {
  return (
    <div style={style} className="callout">
      <p>
        {showIcon && <i className="fa-solid fa-circle-info"></i>}
        {children}
      </p>
    </div>
  );
};
