import React, { useRef, useState, useEffect, useMemo } from 'react';

import { LabelObject } from '../../types';
import { useCustomCategories } from '../hooks';
import CategoryTypeSelect from './CategoryTypeSelect';

import AIDriven from './AIDriven/AIDriven';
import UserDefined from './UDC';
import DerivedLabels from './DerivedLabels/DerivedLabels';
import { useDerivedLabelsState } from './DerivedLabels/hooks';
import { SET_DATA } from './DerivedLabels/reducer';
import AdNameLabels from './AdNameLabels';
import { useAccountId } from '../../selectors';
import AdName from '../../../CreativeReports/Dashboards/AdName';

type CustomCategoriesProps = {
  data: LabelObject[];
  category: string;
  fetchPerformanceData: any;
};

const Custom = ({
  data,
  category,
  fetchPerformanceData,
  udcData,
  udcApi,
  derivedLabelsApi,
  getDerivedLabelsApi,
  allKeywords,
  getAdNameLabelsApi,
  adNameLabelsApi,
  showDerivativeFlow,
  getUdcApi,
}: CustomCategoriesProps) => {
  const account_id = useAccountId();
  const [derivedLabelsState, dispatchDerivedLabels] = useDerivedLabelsState();
  const [showFlow, setShowFlow] = useState(showDerivativeFlow);

  const [stage, next, prev, dropdownOptions, ccc_type, setCCCType, isNew] =
    useCustomCategories(data, category);

  useEffect(() => {
    if (getDerivedLabelsApi.get.data) {
      const match = getDerivedLabelsApi.get.data.category_structure.find(
        (x) => x.category_name === category.category
      );
      if (match) {
        dispatchDerivedLabels({
          type: SET_DATA,
          data: {
            category_name: category.category,
            original_category_name: category.category,
            description: match.description,
            data: match.derived_labels,
            isExisting: true,
            existingCategory: match,
          },
        });
      }
    }
  }, [category, getDerivedLabelsApi.get.data]);

  let Comp = null;

  switch (ccc_type) {
    case 'ai_driven':
      Comp = (
        <AIDriven
          {...{
            stage,
            prev,
            next,
            dropdownOptions,
            category,
            fetchPerformanceData,
            account_id,
          }}
        />
      );
      break;
    case 'user_defined':
      Comp = (
        <UserDefined
          {...{
            stage,
            prev,
            next,
            dropdownOptions,
            udcData,
            category,
            fetchPerformanceData,
            isNew,
            udcApi,
          }}
        />
      );
      break;
    case 'derived_labels':
      Comp = (
        <DerivedLabels
          {...{
            derivedLabelsState,
            dispatchDerivedLabels,
            prev,
            derivedLabelsApi,
            getDerivedLabelsApi,
            allKeywords,
            showFlow,
            setShowFlow,
          }}
        />
      );
      break;
    case 'ad_name_labels':
      Comp = (
        <AdNameLabels
          {...{
            stage,
            prev,
            category,
            isNew,
            data,
            udcApi,
            getAdNameLabelsApi,
            adNameLabelsApi,
          }}
        />
      );
      break;
    default:
      Comp = (
        <AIDriven
          {...{
            stage,
            prev,
            next,
            dropdownOptions,
            category,
            fetchPerformanceData,
          }}
        />
      );
      break;
  }

  return (
    <div
      className={`category-manager custom-categories custom-categories--${ccc_type} stage${stage}`}
    >
      {stage === 0 ? (
        <CategoryTypeSelect {...{ prev, next, ccc_type, setCCCType }} />
      ) : (
        Comp
      )}
    </div>
  );
};

export default Custom;
