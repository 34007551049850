import React from 'react';
import Button from '../../../../components/Button';

const CategoryTypeSelect = ({ next, prev, ccc_type, setCCCType }) => {
  const handleSelect = (selection) => setCCCType(selection);

  return (
    <div className="ccc-select">
      <h1 className="ccc-select__title">Choose Category Type</h1>
      
      <div className="ccc-select-grid">
        <div
          onClick={() => handleSelect('ai_driven')}
          className={`ccc-select__option ${
            ccc_type === 'ai_driven' ? 'ccc-select__option--active' : ''
          }`}
        >
          <h2>A.I. Driven</h2>
          <p>
            Let the AI build the category based on the training dataset you
            provide.
          </p>
        </div>
        <div
          onClick={() => handleSelect('user_defined')}
          className={`ccc-select__option ${
            ccc_type === 'user_defined' ? 'ccc-select__option--active' : ''
          }`}
        >
          <h2>User Defined</h2>
          <p>Use naming conventions to define categories and labels.</p>
        </div>
        <div
          onClick={() => handleSelect('derived_labels')}
          className={`ccc-select__option ${
            ccc_type === 'derived_labels' ? 'ccc-select__option--active' : ''
          }`}
        >
          <h2>Derived Labels</h2>
          <p>Use existing labels to create new ones.</p>
        </div>
        <div
          onClick={() => handleSelect('ad_name_labels')}
          className={`ccc-select__option ${
            ccc_type === 'ad_name_labels' ? 'ccc-select__option--active' : ''
          }`}
        >
          <h2>Ad Name Labels</h2>
          <p>Use ad names to define labels that can be used in derivatives.</p>
        </div>
      </div>

      <Button
        disabled={!ccc_type}
        onClick={next}
        className="category-setup__btn"
      >
        Next
      </Button>
    </div>
  );
};

export default CategoryTypeSelect;
