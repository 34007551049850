import React, { createContext, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';

const GetCtx = createContext(null);
const SetCtx = createContext(null);

export function CreativeAnalyticsProvider({
  filteredData = [],
  data,
  start_date,
  end_date,
  asyncApi,
  account_id,
  display,
  category,
  spendThreshold,
  creativeCount,
  period1,
  period2,
  selected,
  newAds,
  primaryPhraseLabels,
  categoryLabelOptions,
  handleSelectElement,
  editCategory,
  editCategoryVisual,
  isROAS,
  getUdcApi,
  derivedLabelsApi,
  getDerivedLabelsApi,
  setEditCategory,
  setEditCategoryVisual,
  categoryFilters,
  selectedLabels,
  setSelectedLabels,
  setParams,
  clientMetrics,
  setClientMetrics,
  cpasList,
  reportCardDataType,
  reportCardMetric,
  metricOptions,
  requestOpen,
  setRequestOpen,
  selectedAudience,
  reportCardSettings,
  columnDropdownOptions,
  customEvents,
  additional_metrics,
  editBasicLabels,
  setEditBasicLabels,
  activeBoxPlotCategory,
  setActiveBoxPlotCategory,
  resultsThreshold,
  fetchPerformanceData,
  mode,
  libraryView,
  primary_metric,
  isNonPaidMediaType,
  isOrganicView,
  viewName,
  searchTermsData,
  presetsApi,
  groupByAdName,
  defaultPfrColumns,
  defaultDnaColumns,
  defaultDates,
  isEmailView,
  setClustersData,
  clustersApi,
  min,
  loading,
  dnaColumnsParam,
  impactCategories,
  setImpactCategories,
  columns,
  handleColumns,
  customIntegratedMetrics,
  planningMode,
  setPlanningMode,
  coreVisuals,
  setCoreVisuals,
  requestWidth,
  setRequestWidth,
  isGoHealth,
  rightDrawerDisplay,
  fetchRecs,
  recsData,
  idsInLastRequest,
  customScoringData,
  setCustomScoringData,
  iterationsData,
  setIterationsData,
  presetsOpen,
  setPresetsOpen,
  savedConcepts,
  setSavedConcepts,
  trendingRecs,
  inspirationRecs,
  selectedDraftProject,
  setSelectedDraftProject,
  draftCreativeState,
  draftCreativeDispatch,
  isFacebookView,
  isInstagramView,
  isLinkedInView,
  selectedTab,
  isStaticReport,
  showRulesManager,
  setShowRulesManager,
  getRecoRulesData,
  getRecoRulesError,
  getRecoRulesLoading,
  getRecoRulesRequest,
  deleteRecoRulesData,
  deleteRecoRulesLoading,
  deleteRecoRulesRequest,
  saveRecoRulesData,
  saveRecoRulesRequest,
  udcApi,
  allKeywords,
  getAdNameLabelsApi,
  adNameLabelsApi,
  children,
}) {
  const audienceLabels = filteredData.filter(
    (label) => label.category === 'Audience'
  );

  return (
    <GetCtx.Provider
      value={{
        filteredData: filteredData || [],
        data,
        asyncApi,
        account_id,
        display,
        category,
        spendThreshold,
        creativeCount,
        period1,
        period2,
        start_date,
        end_date,
        selected,
        newAds,
        primaryPhraseLabels,
        categoryLabelOptions,
        editCategory,
        editCategoryVisual,
        selectedLabels,
        categoryFilters,
        isROAS,
        getUdcApi,
        derivedLabelsApi,
        getDerivedLabelsApi,
        clientMetrics,
        cpasList,
        reportCardDataType,
        reportCardMetric,
        metricOptions,
        requestOpen,
        selectedAudience,
        reportCardSettings,
        columnDropdownOptions,
        editBasicLabels,
        activeBoxPlotCategory,
        resultsThreshold,
        isNonPaidMediaType,
        isOrganicView,
        fetchPerformanceData,
        mode,
        libraryView,
        primary_metric,
        viewName,
        searchTermsData,
        presetsApi,
        groupByAdName,
        defaultPfrColumns,
        defaultDnaColumns,
        defaultDates,
        isEmailView,
        customEvents,
        additional_metrics,
        clustersApi,
        min,
        loading,
        dnaColumnsParam,
        impactCategories,
        audienceLabels,
        columns,
        handleColumns,
        customIntegratedMetrics,
        planningMode,
        coreVisuals,
        requestWidth,
        isGoHealth,
        rightDrawerDisplay,
        fetchRecs,
        recsData,
        idsInLastRequest,
        customScoringData,
        iterationsData,
        presetsOpen,
        savedConcepts,
        trendingRecs,
        inspirationRecs,
        selectedDraftProject,
        draftCreativeState,
        draftCreativeDispatch,
        isFacebookView,
        isInstagramView,
        isLinkedInView,
        isStaticReport,
        selectedTab,
        isStaticReport,
        showRulesManager,
        getRecoRulesData,
        getRecoRulesError,
        getRecoRulesLoading,
        getRecoRulesRequest,
        deleteRecoRulesData,
        deleteRecoRulesLoading,
        deleteRecoRulesRequest,
        saveRecoRulesData,
        saveRecoRulesRequest,
        udcApi,
        allKeywords,
        getAdNameLabelsApi,
        adNameLabelsApi,
      }}
    >
      <SetCtx.Provider
        value={{
          setParams,
          handleSelectElement,
          setEditCategory,
          setEditCategoryVisual,
          setSelectedLabels,
          setClientMetrics,
          setRequestOpen,
          setEditBasicLabels,
          setActiveBoxPlotCategory,
          setClustersData,
          setImpactCategories,
          setPlanningMode,
          setCoreVisuals,
          setRequestWidth,
          setCustomScoringData,
          setIterationsData,
          setPresetsOpen,
          setSavedConcepts,
          setSelectedDraftProject,
          setShowRulesManager,
        }}
      >
        {children}
      </SetCtx.Provider>
    </GetCtx.Provider>
  );
}

export function useCreativeAnalyticsGet() {
  return useContext(GetCtx);
}

export function useCreativeAnalyticsSet() {
  return useContext(SetCtx);
}

const ApiContext = createContext(null);

export const AnalyticsApiProvider = ({ fetchPresetsData, children }) => {
  return (
    <ApiContext.Provider
      value={{
        fetchPresetsData,
      }}
    >
      {children}
    </ApiContext.Provider>
  );
};

export default AnalyticsApiProvider;

export function useAnalyticsApiContext() {
  return useContext(ApiContext);
}
