import { toPercentRounded } from '../../../../../utils/numbers';
import { useCustomScoringApi } from '../../../../DNA/api';
import { DARWIN_INDEX_LOOKUP } from '../../../constants';
import { shouldIncludeLabel } from '../../../filter-utils';
import { SKY_ZONE_CUSTOM_METRICS } from '../../../PerformanceFrequency/constants';
import { VisualObject } from '../../../types';
import {
  getPercentBudget,
  getTotal,
  getTotalLikes,
  useGetAggregate,
  useGetAverage,
  useGetMedian,
  useGetPercentResults,
  useGetPercentResultsDiff,
  useGetTotalResults,
} from '../../../utils';
import { getAdditionalMetrics } from '../../../utils/getAdditionalMetrics';
import { getCustomIntegratedMetricsValues } from '../../../utils/getCustomIntegratedMetricsValues';
import { getSumsAndAverages } from '../../../utils/getSumsAndAverages';
import { getTotalImpressions } from '../../../utils/getTotalImpressions';
import { getAggregate } from './getAggregate';
import { getAverage } from './getAverage';
import { getClientMetricValues } from './getClientMetricValues';
import { getCustomEventsValues } from './getCustomEventsValues';
import { getCustomScoringValues } from './getCustomScoringValues';
import { getMedian } from './getMedian';
import { getReach } from './getReach';
import { modifyLabel } from './modifyLabel';

const findDiscrepancies = (combined, byId, label) => {
  return combined.some((visual) => {
    const maybe = byId[visual.id];

    if (!maybe) return false;
    if (maybe?.spend !== visual?.spend) {
      return true;
    }
  });
};

const getWinnersAndLosers = ({
  label,
  filteredWinnerIds,
  filteredLoserIds,
  shouldUseOwnVisualsData,
  handleFilterVisuals,
  sumsAndAverages,
}): {
  winner_ids: string[];
  loser_ids: string[];
  winners: VisualObject[];
  losers: VisualObject[];
} => {
  const { relevant_visuals } = label;
  const visual_ids =
    relevant_visuals ??
    [...label.winners, ...label.losers].map(
      (visual) => visual.id || visual._id
    );
  /* 
  For the most part, only ids should be returned. 
  For udc, visuals need to be calculated/filtered separately
  */

  try {
    if (shouldUseOwnVisualsData) {
      const { winners, losers } = handleFilterVisuals(
        [...(relevant_visuals ?? [...label.winners, ...label.losers])],
        sumsAndAverages
      );

      return {
        winner_ids: winners.map((obj) => obj.id),
        loser_ids: losers.map((obj) => obj.id),
        winners,
        losers,
      };
    } else {
      return {
        winner_ids: visual_ids.filter((id) => filteredWinnerIds.includes(id)),
        loser_ids: visual_ids.filter((id) => filteredLoserIds.includes(id)),
        winners: [],
        losers: [],
      };
    }
  } catch (e) {
    console.error(e);
    return {
      winner_ids: label.winners?.map(({ id }) => id) || [],
      loser_ids: label.losers?.map(({ id }) => id) || [],
      winners: label.winners || [],
      losers: label.losers || [],
    };
  }
};

const isListOfObjects = (arr) => {
  return Array.isArray(arr) && !!arr[0] && typeof arr[0] === 'object';
};

export const filterLabels = ({
  data,
  addSynonymLabel,
  filteredAllVisuals,
  handleFilterVisuals,
  visualsData,
  filteredWinnerIds,
  filteredLoserIds,
  isOrganicView,
  sumsAndAverages,
  isROAS,
  categoryFilters,
  customIntegratedMetrics,
  additional_metrics,
  clientMetrics,
  isDirectMail,
  isCompetitorView,
  creativeCount,
  customEvents,
  totalImpressions,
  customScoringData,
  isNoom,
  isSkyZone,
  isWisdomPanel,
}) => {
  try {
    return data.reduce((acc, curr, i) => {
      const label = modifyLabel(curr);
      const { category } = label;

      addSynonymLabel(label, curr, (synonymOthersLabel) =>
        acc.push(synonymOthersLabel)
      );

      const isDiscrepancy = findDiscrepancies(
        [...(label.winners ?? []), ...(label.losers ?? [])],
        visualsData.lookup,
        label
      );

      const shouldUseOwnVisualsData =
        label.udc || isDiscrepancy || isListOfObjects(label.relevant_visuals);

      const {
        winner_ids,
        loser_ids,
        winners,
        losers,
      }: {
        winner_ids: string[];
        loser_ids: string[];
        winners: VisualObject[];
        losers: VisualObject[];
      } = getWinnersAndLosers({
        label,
        filteredWinnerIds,
        filteredLoserIds,
        shouldUseOwnVisualsData,
        handleFilterVisuals,
        sumsAndAverages,
      });

      const visual_ids: string[] = [...winner_ids, ...loser_ids];
      const visuals: VisualObject[] = shouldUseOwnVisualsData
        ? [...winners, ...losers]
        : visual_ids.reduce((acc, id) => {
            const match = visualsData.lookup.get(id);
            if (match) acc.push(match);
            return acc;
          }, []);

      const labelSumsAndAverages = getSumsAndAverages(visuals, isOrganicView);

      const totalBudget = sumsAndAverages.sumSpend;
      const spend = labelSumsAndAverages.sumSpend;

      const customIntegratedMetricsValues = getCustomIntegratedMetricsValues(
        visuals,
        customIntegratedMetrics,
        spend
      );
      const additionalMetricsValues = getAdditionalMetrics(
        visuals,
        additional_metrics,
        spend
      );

      const skyZoneCustomMetrics = isSkyZone
        ? SKY_ZONE_CUSTOM_METRICS.reduce((acc, metric) => {
            const totals = additional_metrics.reduce((acc, metric) => {
              acc[metric.value] = getTotal(visuals, metric.value);

              return acc;
            }, {});

            const totalSpend = getTotal(visuals, 'spend');

            if (totalSpend > 0) {
              if (metric === 'Ticket ROAS') {
                const revenue =
                  (totals['onsite_web_purchase'] * 32) /
                  getTotal(visuals, 'spend');
                acc[metric] = revenue;
              } else if (metric === 'Annual Pass ROAS') {
                acc[metric] =
                  (totals[
                    'offsite_conversion.fb_pixel_custom.PurchaseAnnualPass'
                  ] *
                    195) /
                  totalSpend;
              } else if (metric === 'Discounted Annual Pass ROAS') {
                acc[metric] =
                  (totals[
                    'offsite_conversion.fb_pixel_custom.PurchaseAnnualPass'
                  ] *
                    175) /
                  totalSpend;
              }
            } else {
              acc[metric] = 0;
            }

            return acc;
          }, {})
        : {};

      const temporarilyRemoved = [];

      const shouldInclude =
        shouldIncludeLabel(label, categoryFilters) &&
        !temporarilyRemoved.includes(category);

      const clientMetricsValues: {} = getClientMetricValues({
        clientMetrics,
        isDirectMail,
        isCompetitorView,
        isOrganicView,
        visuals,
        isNoom,
      });

      const customEventsValues: {} = getCustomEventsValues({
        customEvents,
        visuals,
        numOfAllVisuals: filteredAllVisuals.length,
        totalImpressions,
      });

      const total_likes = getTotalLikes(visuals);
      const average_cpa = getAverage(visuals, 'cpa');
      const average_roas = getAverage(visuals, 'roas');
      const customScoringValues = getCustomScoringValues(
        visuals,
        customScoringData
      );

      if (
        shouldInclude &&
        (visual_ids.length >= parseInt(creativeCount) ||
          label.testing_opportunity)
      ) {
        acc.push({
          ...label,
          id: `${category}->${label.name}`,
          category,
          numerator: winner_ids.length,
          denominator: visual_ids.length,
          performance_percent: Math.round(
            (winner_ids.length / visual_ids.length) * 100
          ),
          ...clientMetricsValues,
          ...customScoringValues,
          aggregate_cpa: getAggregate(visuals, 'cpa'),
          average_cpa,
          median_cpa: getMedian(visuals, 'cpa'),
          aggregate_roas: getAggregate(visuals, 'roas'),
          average_roas,
          median_roas: getMedian(visuals, 'roas'),
          aggregate_cpm: getAggregate(visuals, 'cpm'),
          average_cpm: getAverage(visuals, 'cpm'),
          median_cpm: getMedian(visuals, 'cpm'),
          cpm_reach: getReach(visuals),
          aggregate_cpc: getAggregate(visuals, 'cpc'),
          average_cpc: getAverage(visuals, 'cpc'),
          median_cpc: getMedian(visuals, 'cpc'),
          aggregate_ctr: getAggregate(visuals, 'ctr'),
          average_ctr: getAverage(visuals, 'ctr'),
          median_ctr: getMedian(visuals, 'ctr'),
          aggregate_cpik: getAggregate(visuals, 'cpik'),
          average_cpik: getAverage(visuals, 'cpik'),
          median_cpik: getMedian(visuals, 'cpik'),
          aggregate_cost_per_estimated_ad_recallers: getAggregate(
            visuals,
            'cp_recallers'
          ),
          median_cost_per_estimated_ad_recallers: getMedian(
            visuals,
            'cp_recallers'
          ),
          aggregate_conversion_rate: getAggregate(visuals, 'conversion_rate'),
          median_conversion_rate: getMedian(visuals, 'conversion_rate'),
          ga_aggregate_ctr: getAggregate(visuals, 'ga_ctr'),
          ga_average_ctr: getAverage(visuals, 'ga_ctr'),
          ga_median_ctr: getMedian(visuals, 'ga_ctr'),
          total_results: useGetTotalResults(visuals, 'results', isROAS),
          percent_of_results: useGetPercentResults(
            visuals,
            'results',
            sumsAndAverages.sumResults,
            isROAS
          ),
          percent_of_results_diff: useGetPercentResultsDiff(
            visuals,
            'results',
            sumsAndAverages.sumResults,
            totalBudget,
            isROAS
          ),
          total_link_clicks: useGetTotalResults(visuals, 'clicks'),
          percent_of_link_clicks: useGetPercentResults(
            visuals,
            'clicks',
            sumsAndAverages.sumClicks
          ),
          percent_of_link_clicks_diff: useGetPercentResultsDiff(
            visuals,
            'clicks',
            sumsAndAverages.sumClicks,
            totalBudget
          ),
          number_unique_creatives: visual_ids.length,
          percent_of_creative_count: toPercentRounded(
            visuals.length / filteredAllVisuals.length
          ),
          winners,
          losers,
          winner_ids,
          loser_ids,
          percent_budget: getPercentBudget(visuals, totalBudget),
          spend,
          darwin_index: DARWIN_INDEX_LOOKUP[label.name] || '-',
          // visuals,
          visual_ids,
          wins_over_all: `${winner_ids.length}/${visual_ids.length}`,
          client_down_funnel_events: clientMetrics,
          total_likes,
          likes_per_post:
            visuals.length > 0 ? (total_likes / visuals.length).toFixed(2) : 0,
          average_roas_quintile: label.average_cpa_quintile,
          percent_of_visuals: toPercentRounded(
            visuals.length / filteredAllVisuals.length
          ),
          ad_name_label:
            label.type === 'ad_name_label' ||
            label.category === 'Ad Name Label',
          total_impressions: getTotalImpressions(visuals),
          top_spending_visual_id: visuals[0]?.id ?? null,
          shouldUseOwnVisualsData,
          ...(additionalMetricsValues ?? {}),
          ...(customIntegratedMetricsValues ?? {}),
          ...(clientMetricsValues ?? {}),
          ...(customEventsValues ?? {}),
          ...(skyZoneCustomMetrics ?? {})
        });
      }

      return acc;
    }, []);
  } catch (e) {
    console.log(e);
    return [];
  }
};
