import React from 'react';
import flatten from 'lodash/flatten';
import { useViewSettings } from '../../../../selectors';
import { toCurrency, toCurrency_4, toFixed_2 } from '../../../../utils/numbers';
import { useCreativeAnalyticsGet } from '../../contexts';
import { SKY_ZONE_CUSTOM_METRICS } from '../constants';
import { useSelector } from 'react-redux';

export const useAdditionalMetricsColumns = () => {
  const {
    account_id,
    isGoHealth,
    selectedTab,
  } = useCreativeAnalyticsGet();
  const { isSkyZone, isNoom, additional_metrics, isWisdomPanel } = useSelector(
    ({
      ViewSettings: { isSkyZone, isNoom, additional_metrics, isWisdomPanel },
    }) => ({
      isSkyZone,
      isNoom,
      additional_metrics,
      isWisdomPanel,
    })
  );
  const isUBeauty = account_id === 'act_478482719415309';

  if (!additional_metrics || isNoom) return [];

  const conversionsMetric = additional_metrics.find((m) =>
    m.name.includes('Conversions')
  );

  const goHealthCols = [
    {
      Header: `Cost per Opp Attempted`,
      accessor: 'cp_offsite_conversion.fb_pixel_custom.OppAttempted',
      disableFilters: true,
      Cell: ({ row: { original } }) => {
        const value =
          original['cp_offsite_conversion.fb_pixel_custom.OppAttempted'];
        if (!value) return <p>N/A</p>;
        return <p>{toCurrency(value)}</p>;
      },
    },
    {
      Header: `Median cost per Opp Attempted`,
      accessor: 'median_cp_offsite_conversion.fb_pixel_custom.OppAttempted',
      disableFilters: true,
      Cell: ({ row: { original } }) => {
        const value =
          original['median_cp_offsite_conversion.fb_pixel_custom.OppAttempted'];
        if (!value) return <p>N/A</p>;
        return <p>{toCurrency(value)}</p>;
      },
    },
  ];

  const skyZoneCols = isSkyZone
    ? SKY_ZONE_CUSTOM_METRICS.map((metric) => {
        const col = {
          Header: metric,
          accessor: metric,
          disableFilters: true,
          Cell: ({ row: { original } }) => {
            const value = original[metric];
            if (!value) return <p>N/A</p>;
            return <p>{value.toFixed(2)}</p>;
          },
        };

        return col;
      })
    : [];

  const isWpPro = selectedTab?.tab_name.includes('Prospecting');
  const isWpRtg = selectedTab?.tab_name.includes('Retargeting');

  const wisdomPanelCols =
    isWpPro || isWpRtg
      ? [
          {
            Header: `WP ${isWpPro ? 'PRO' : 'RTG'} ROAS`,
            accessor: `WP ${isWpPro ? 'PRO' : 'RTG'} ROAS`,
            disableFilters: true,
            Cell: ({ row: { original } }) => {
              const multiplier = isWpPro ? 0.45 : 0.2;
              const value = original.aggregate_roas * multiplier;
              if (!value) return <p>N/A</p>;
              return <p>{value.toFixed(2)}</p>;
            },
          },
        ]
      : [];

  const additionalMetricsColumns = isUBeauty
    ? [
        {
          Header: 'Rockerbox Revenue',
          accessor: 'value_Rockerbox_ROAS',
          disableFilters: true,
          Cell: ({ row: { original } }) => {
            const value = original['value_Rockerbox_ROAS'];
            if (!value) return <p>N/A</p>;
            return <p>{toCurrency(value)}</p>;
          },
        },
        {
          Header: `Rockerbox Roas`,
          accessor: 'Rockerbox_Roas',
          disableFilters: true,
          Cell: ({ row: { original } }) => {
            const { spend, value_Rockerbox_ROAS: revenue } = original;
            if (spend <= 0) return <p>N/A</p>;
            return <p>{(revenue / spend).toFixed(2)}</p>;
          },
        },
        {
          Header: `Total ${conversionsMetric.name}`,
          accessor: conversionsMetric.value,
          disableFilters: true,
          Cell: ({ row: { original } }) => {
            const value = original[conversionsMetric.value];
            if (!value) return <p>N/A</p>;
            return <p>{value.toFixed(2)}</p>;
          },
        },
        {
          Header: `Cost Per ${conversionsMetric.name}`,
          accessor: `cp_${conversionsMetric.value}`,
          disableFilters: true,
          minWidth: 200,
          Cell: ({ row: { original } }) => {
            const value = original[`cp_${conversionsMetric.value}`];
            if (!value) return <p>N/A</p>;
            return <p>{toCurrency(value)}</p>;
          },
        },
        {
          Header: `Median Cost Per ${conversionsMetric.name}`,
          accessor: `median_cp_${conversionsMetric.value}`,
          disableFilters: true,
          minWidth: 200,
          Cell: ({ row: { original } }) => {
            const value = original[`median_cp_${conversionsMetric.value}`];
            if (!value) return <p>N/A</p>;
            return <p>{toCurrency(value)}</p>;
          },
        },
      ]
    : isGoHealth
    ? goHealthCols
    : [
        ...additional_metrics.map((metric) => {
          return [
            {
              Header: `Total ${metric.name}`,
              accessor: metric.value,
              disableFilters: true,
              Cell: ({ row: { original } }) => {
                const value = original[metric.value];
                if (!value) return <p>N/A</p>;
                return <p>{value.toFixed(2)}</p>;
              },
            },
            {
              Header: `Cost Per ${metric.name}`,
              accessor: `cp_${metric.value}`,
              disableFilters: true,
              minWidth: 200,
              Cell: ({ row: { original } }) => {
                const value = original[`cp_${metric.value}`];
                if (!value) return <p>N/A</p>;
                return <p>{toCurrency_4(value)}</p>;
              },
            },
            {
              Header: `Median ${metric.name}`,
              accessor: `median_${metric.value}`,
              disableFilters: true,
              minWidth: 200,
              Cell: ({ row: { original } }) => {
                const value = original[`median_${metric.value}`];
                if (!value) return <p>N/A</p>;
                return <p>{toFixed_2(value)}</p>;
              },
            },
            {
              Header: `Median Cost Per ${metric.name}`,
              accessor: `median_cp_${metric.value}`,
              disableFilters: true,
              minWidth: 200,
              Cell: ({ row: { original } }) => {
                const value = original[`median_cp_${metric.value}`];
                if (!value) return <p>N/A</p>;
                return <p>{toCurrency_4(value)}</p>;
              },
            },
            ...skyZoneCols,
            ...wisdomPanelCols,
          ];
        }),
        ...skyZoneCols,
        ...wisdomPanelCols,
      ];

  return flatten(additionalMetricsColumns);
};
