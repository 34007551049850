import React from 'react';
import { useDispatch } from 'react-redux';
import { setRightDrawerDisplay } from '../../../store/CreativeAnalytics/creativeAnalyticsSlice';
import { toCurrency } from '../../../utils/numbers';
import {
  BOTTOM_5_PERFORMING,
  BOTTOM_5_SPENDING,
  TOP_5_PERFORMING,
  TOP_5_SPENDING,
} from '../groups';
import { CreativeGroup } from '../types';
import { generateTitle } from './utils';
import { useCreativeAnalyticsGet } from '../../CreativeAnalytics/contexts';
import IconButton from '../../../components/IconButton';

import './Editor.scss';
import { RIGHT_RAIL_WIDTH } from '../constants';
import { useDNAColumnOptions } from '../useDNAColumnOptions';
import { RightDrawerWrapper } from '../../CreativeAnalytics/RightDrawer';
import { useViewSettings } from '../../../selectors';

const Header = ({ title, setIsEditing }) => {
  return (
    <div className="right-drawer-header">
      <p className="">{title}</p>
      <IconButton onClick={() => setIsEditing(false)}>
        <i className="fa-solid fa-xmark"></i>
      </IconButton>
    </div>
  );
};

const InputGroup = ({ label, children }) => {
  return (
    <div className="dna-input-group">
      <label>{label}</label>
      {children}
    </div>
  );
};

const List = ({
  groupParams,
  setGroupParams,
  isEditing,
  setIsEditing,
  options,
}) => {
  return (
    <ul className="creatives-select-list">
      {options.map((option) => (
        <li onClick={() => setGroupParams(option)}>
          <div>
            <p className="missing-data-list__name">{option.title}</p>
          </div>
        </li>
      ))}
    </ul>
  );
};

export const Editor = ({
  groupParams,
  setGroupParams,
  metadata,
  setIsEditing,
}) => {
  const {
    title,
    count,
    selectBy,
    metric,
    sortBy,
    sortOrder,
    selectedLabel,
  }: CreativeGroup = groupParams;
  const { filteredData, isROAS, clientMetrics, customEvents, selectedTab } =
    useCreativeAnalyticsGet();
  const columnOptions = useDNAColumnOptions({
    isROAS,
    clientMetrics,
    customEvents,
    selectedTab,
  });
  const { isOrganicView } = useViewSettings();
  const metrics = columnOptions.filter((x) => !x.isMetadata);

  const labelOptions = [...filteredData].reduce((result, curr) => {
    const labelIsInMetadata = metadata?.categories?.some((c) =>
      c.category_values.includes(curr.value)
    );
    if (labelIsInMetadata) {
      result.push({
        label: curr.name,
        value: curr.value,
      });
    }

    return result;
  }, []);

  const handleUpdate = (field, value) => {
    ({ field, value, setGroupParams });
    const update = {
      ...groupParams,
      custom: true,
      [field]: value,
    };
    const updatedTitle = generateTitle(update);

    setGroupParams({
      ...update,
      title: updatedTitle,
    });
  };

  return (
    <RightDrawerWrapper
      style={{
        minWidth: `${RIGHT_RAIL_WIDTH}px`,
        maxWidth: `${RIGHT_RAIL_WIDTH}px`,
        borderLeft: '1px solid #ECF1F9',
      }}
    >
      <div style={{ minWidth: `${RIGHT_RAIL_WIDTH}px` }}>
        <Header title={title} setIsEditing={setIsEditing} />
        <div className="dna-editor">
          <InputGroup label="Select by...">
            <div>
              <select
                value={selectBy}
                onChange={(e) => handleUpdate('selectBy', e.target.value)}
              >
                <option value="">All</option>
                <option value="top">Top</option>
                <option value="bottom">Bottom</option>
                <option value="element">Element</option>
                {/* <option value="custom">Custom</option> */}
              </select>
            </div>

            {(selectBy === 'top' || selectBy === 'bottom') && (
              <>
                <div>
                  <label>Count</label>
                  <input
                    value={count || 1}
                    onChange={(e) => handleUpdate('count', e.target.value)}
                    type="number"
                    min={1}
                  ></input>
                </div>

                {!isOrganicView && (
                  <div className="double-select">
                    <button
                      className={metric !== 'spend' ? 'is-selected' : ''}
                      onClick={() => handleUpdate('metric', 'cpa')}
                    >
                      Performing
                    </button>
                    <button
                      className={metric === 'spend' ? 'is-selected' : ''}
                      onClick={() => handleUpdate('metric', 'spend')}
                    >
                      Spending
                    </button>
                  </div>
                )}

                {metric !== 'spend' && (
                  <div>
                    <label>Performance Metric</label>
                    <select
                      value={metric}
                      onChange={(e) => handleUpdate('metric', e.target.value)}
                    >
                      {metrics.map(({ label, value }) => {
                        return (
                          <option value={value} selected={value === metric}>
                            {label}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                )}
              </>
            )}

            {selectBy === 'element' && (
              <div>
                <label>Element</label>
                <select
                  value={selectedLabel}
                  onChange={(e) =>
                    handleUpdate('selectedLabel', e.target.value)
                  }
                >
                  {labelOptions.map((opt) => (
                    <option value={opt.value}>{opt.label}</option>
                  ))}
                </select>
              </div>
            )}
          </InputGroup>

          <InputGroup label="Sort by">
            <div>
              <select
                value={sortBy}
                onChange={(e) => handleUpdate('sortBy', e.target.value)}
              >
                <option value={''}>-</option>
                {metrics.map(({ label, value }) => {
                  return <option value={value}>{label}</option>;
                })}
              </select>
            </div>
            <div>
              <select
                value={sortOrder}
                onChange={(e) => handleUpdate('sortOrder', e.target.value)}
              >
                <option value="descending">
                  Descending (highest to lowest)
                </option>
                <option value="ascending">Ascending (lowest to highest)</option>
              </select>
            </div>
          </InputGroup>
        </div>
      </div>
    </RightDrawerWrapper>
  );
};
