import React, { useState, useEffect } from 'react';

export const ArrowLeft = ({ prev }) => {
  return (
    <div className="recommendation-card__arrow prev" onClick={prev}>
      <svg
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="0.744568"
          y="0.583435"
          width="20.51"
          height="20.51"
          rx="10.255"
          fill="white"
          fillOpacity="1"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.2084 16.5051L6.54177 10.8384L12.2084 5.17178L13.6286 6.58844L9.3751 10.8384L13.6286 15.0884L12.2084 16.5051Z"
          fill="#3A4B77"
          fillOpacity="0.8"
        />
      </svg>
    </div>
  );
};

export const ArrowRight = ({ next }) => {
  return (
    <div className="recommendation-card__arrow next" onClick={next}>
      <svg
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="0.824463"
          y="0.583435"
          width="20.51"
          height="20.51"
          rx="10.255"
          fill="white"
          fillOpacity="1"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.41353 5.17177L15.0802 10.8384L9.41353 16.5051L7.99332 15.0884L12.2469 10.8384L7.99332 6.58844L9.41353 5.17177Z"
          fill="#3A4B77"
          fillOpacity="0.8"
        />
      </svg>
    </div>
  );
};

export const CopyIcon = ({ copy }) => {
  return (
    <div
      title="Copy text"
      className="recommendation-card__copy"
      onClick={copy}
    >
      <svg
        width="16"
        height="19"
        viewBox="0 0 16 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0)">
          <path
            d="M15.4979 2.44703L13.645 0.521721C13.3235 0.187671 12.8874 2.47028e-06 12.4328 0L6.28571 0C5.33893 0 4.57143 0.79748 4.57143 1.78125V3.5625H1.71429C0.7675 3.5625 0 4.35998 0 5.34375V17.2188C0 18.2025 0.7675 19 1.71429 19H9.71429C10.6611 19 11.4286 18.2025 11.4286 17.2188V15.4375H14.2857C15.2325 15.4375 16 14.64 16 13.6562V3.70656C16 3.23414 15.8194 2.78108 15.4979 2.44703ZM9.5 17.2188H1.92857C1.87174 17.2188 1.81723 17.1953 1.77705 17.1535C1.73686 17.1118 1.71429 17.0551 1.71429 16.9961V5.56641C1.71429 5.50735 1.73686 5.45072 1.77705 5.40896C1.81723 5.36721 1.87174 5.34375 1.92857 5.34375H4.57143V13.6562C4.57143 14.64 5.33893 15.4375 6.28571 15.4375H9.71429V16.9961C9.71429 17.0551 9.69171 17.1118 9.65152 17.1535C9.61134 17.1953 9.55683 17.2188 9.5 17.2188ZM14.0714 13.6562H6.5C6.44317 13.6562 6.38866 13.6328 6.34848 13.591C6.30829 13.5493 6.28571 13.4926 6.28571 13.4336V2.00391C6.28571 1.94485 6.30829 1.88822 6.34848 1.84646C6.38866 1.80471 6.44317 1.78125 6.5 1.78125H10.2857V5.04688C10.2857 5.53876 10.6695 5.9375 11.1429 5.9375H14.2857V13.4336C14.2857 13.4926 14.2631 13.5493 14.223 13.591C14.1828 13.6328 14.1283 13.6562 14.0714 13.6562V13.6562ZM14.2857 4.15625H12V1.78125H12.344C12.4008 1.78125 12.4553 1.8047 12.4955 1.84645L14.223 3.64136C14.2429 3.66204 14.2586 3.68658 14.2694 3.7136C14.2802 3.74062 14.2857 3.76957 14.2857 3.79881V4.15625Z"
            fill="#1E2B4E"
          />
        </g>
        <defs>
          <clipPath id="clip0">
            <rect width="16" height="19" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export const CopyPopup = ({ setCopied }) => {
  const [alert, setAlert] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setAlert(false);
      setCopied(false);
    }, 3000);
  }, []);

  if (!alert) return null;
  return <div className="recommendation-card__popup">Copied!</div>;
};

export const StarIcon = ({ onClick, className }) => {
  return (
    <svg
      {...{ onClick, className }}
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.48131 2.03647C9.95022 0.59331 11.9919 0.593311 12.4608 2.03648L13.9018 6.47125C14.1115 7.11665 14.7129 7.55362 15.3915 7.55362H20.0545C21.572 7.55362 22.2029 9.49539 20.9752 10.3873L17.2028 13.1282C16.6538 13.527 16.4241 14.2341 16.6338 14.8795L18.0747 19.3142C18.5436 20.7574 16.8919 21.9575 15.6642 21.0656L11.8918 18.3247C11.3428 17.9258 10.5994 17.9258 10.0503 18.3247L6.2779 21.0656C5.05027 21.9575 3.39851 20.7574 3.86742 19.3142L5.30836 14.8795C5.51807 14.2341 5.28834 13.527 4.73933 13.1282L0.966882 10.3873C-0.260747 9.49539 0.370172 7.55362 1.88761 7.55362H6.5506C7.22922 7.55362 7.83066 7.11665 8.04036 6.47125L9.48131 2.03647Z"
        fill="#DDE6F3"
      />
    </svg>
  );
};

export const StarIconHalf = ({ onClick, className }) => {
  return (
    <svg
      {...{ onClick, className }}
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.48131 2.03647C9.95022 0.59331 11.9919 0.593311 12.4608 2.03648L13.9018 6.47125C14.1115 7.11665 14.7129 7.55362 15.3915 7.55362H20.0545C21.572 7.55362 22.2029 9.49539 20.9752 10.3873L17.2028 13.1282C16.6538 13.527 16.4241 14.2341 16.6338 14.8795L18.0747 19.3142C18.5436 20.7574 16.8919 21.9575 15.6642 21.0656L11.8918 18.3247C11.3428 17.9258 10.5994 17.9258 10.0503 18.3247L6.2779 21.0656C5.05027 21.9575 3.39851 20.7574 3.86742 19.3142L5.30836 14.8795C5.51807 14.2341 5.28834 13.527 4.73933 13.1282L0.966882 10.3873C-0.260747 9.49539 0.370172 7.55362 1.88761 7.55362H6.5506C7.22922 7.55362 7.83066 7.11665 8.04036 6.47125L9.48131 2.03647Z"
        fill="#FBCE4C"
        fill-opacity="1"
      />
    </svg>
  );
};

export const EyeIcon = ({ onClick, className }) => {
  return (
    <svg
      {...{ onClick, className }}
      width="18"
      height="12"
      viewBox="0 0 18 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.8913 5.54375C16.1966 2.23719 12.8416 0 9.00002 0C5.15845 0 1.8025 2.23875 0.10875 5.54406C0.0372632 5.68547 1.71661e-05 5.8417 1.71661e-05 6.00016C1.71661e-05 6.15861 0.0372632 6.31484 0.10875 6.45625C1.80344 9.76281 5.15845 12 9.00002 12C12.8416 12 16.1975 9.76125 17.8913 6.45594C17.9628 6.31453 18 6.1583 18 5.99984C18 5.84139 17.9628 5.68516 17.8913 5.54375V5.54375ZM9.00002 10.5C8.11 10.5 7.23997 10.2361 6.49995 9.74161C5.75992 9.24715 5.18315 8.54434 4.84255 7.72208C4.50196 6.89981 4.41284 5.99501 4.58648 5.12209C4.76011 4.24918 5.18869 3.44736 5.81803 2.81802C6.44737 2.18868 7.24919 1.7601 8.12211 1.58647C8.99502 1.41283 9.89983 1.50195 10.7221 1.84254C11.5444 2.18314 12.2472 2.75991 12.7416 3.49993C13.2361 4.23995 13.5 5.10998 13.5 6C13.5003 6.59103 13.3841 7.17632 13.1581 7.72242C12.932 8.26851 12.6006 8.7647 12.1826 9.18262C11.7647 9.60054 11.2685 9.932 10.7224 10.158C10.1763 10.3841 9.59105 10.5003 9.00002 10.5V10.5ZM9.00002 3C8.73225 3.00374 8.4662 3.04358 8.20908 3.11844C8.42102 3.40646 8.52273 3.7609 8.49575 4.11748C8.46878 4.47406 8.31491 4.80917 8.06205 5.06203C7.80918 5.31489 7.47408 5.46876 7.1175 5.49574C6.76091 5.52271 6.40647 5.42101 6.11845 5.20906C5.95444 5.81331 5.98404 6.45377 6.2031 7.04031C6.42216 7.62685 6.81963 8.12993 7.33958 8.47874C7.85953 8.82754 8.47577 9.00452 9.10157 8.98475C9.72737 8.96498 10.3312 8.74946 10.8281 8.36853C11.325 7.9876 11.6899 7.46044 11.8715 6.86125C12.0531 6.26205 12.0423 5.62099 11.8404 5.0283C11.6386 4.43561 11.256 3.92114 10.7465 3.55728C10.2369 3.19343 9.62613 2.99853 9.00002 3V3Z"
        fill="#ccc"
      />
    </svg>
  );
};

export const EyeIconHide = ({ onClick, className }) => {
  return (
    <svg
      {...{ onClick, className }}
      width="20"
      height="16"
      viewBox="0 0 20 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.9999 12.5C7.6296 12.5 5.71085 10.6653 5.53429 8.34031L2.25618 5.8069C1.82524 6.34752 1.42868 6.91907 1.10868 7.54407C1.03719 7.68547 0.999946 7.84171 0.999946 8.00016C0.999946 8.15861 1.03719 8.31484 1.10868 8.45625C2.80336 11.7628 6.15835 13.9999 9.9999 13.9999C10.8408 13.9999 11.6521 13.8749 12.434 13.6731L10.8124 12.4184C10.5446 12.4701 10.2726 12.4974 9.9999 12.5ZM19.8067 14.3156L16.3521 11.6456C17.4015 10.7612 18.2646 9.67695 18.8911 8.45593C18.9626 8.31453 18.9999 8.15829 18.9999 7.99984C18.9999 7.84139 18.9626 7.68516 18.8911 7.54375C17.1964 4.23723 13.8415 2.00006 9.9999 2.00006C8.391 2.00201 6.80823 2.40705 5.39617 3.17817L1.42055 0.105393C1.36871 0.0650525 1.30943 0.0353224 1.24609 0.0179022C1.18275 0.000481958 1.1166 -0.004287 1.05142 0.00386784C0.98624 0.0120227 0.923305 0.0329415 0.866212 0.0654288C0.809118 0.0979162 0.758986 0.141335 0.71868 0.193205L0.105245 0.982884C0.0238694 1.08757 -0.0125943 1.22028 0.00387315 1.35185C0.0203406 1.48341 0.0883907 1.60304 0.193057 1.68444L18.5793 15.8946C18.6311 15.9349 18.6904 15.9647 18.7537 15.9821C18.8171 15.9995 18.8832 16.0043 18.9484 15.9961C19.0136 15.988 19.0765 15.9671 19.1336 15.9346C19.1907 15.9021 19.2408 15.8587 19.2811 15.8068L19.8949 15.0171C19.9762 14.9124 20.0126 14.7797 19.9961 14.6481C19.9796 14.5165 19.9115 14.3969 19.8067 14.3156ZM14.0655 9.87811L12.8374 8.92874C12.9408 8.62983 12.9957 8.31627 12.9999 8C13.006 7.53696 12.9034 7.07895 12.7004 6.66275C12.4974 6.24654 12.1996 5.88376 11.8309 5.60353C11.4622 5.3233 11.033 5.13344 10.5776 5.0492C10.1223 4.96496 9.65349 4.98868 9.20897 5.11847C9.39741 5.37381 9.49934 5.68267 9.4999 6.00002C9.49524 6.10562 9.4791 6.21041 9.45178 6.31252L7.15147 4.53472C7.95053 3.86698 8.95857 3.50082 9.9999 3.50005C10.5909 3.49972 11.1762 3.61589 11.7224 3.84192C12.2685 4.06794 12.7647 4.39939 13.1826 4.81732C13.6005 5.23524 13.932 5.73145 14.158 6.27755C14.384 6.82366 14.5002 7.40897 14.4999 8C14.4999 8.67593 14.3346 9.30592 14.0655 9.87842V9.87811Z"
        fill="#ccc"
      />
    </svg>
  );
};

export const ResetIcon = ({ onClick, className }) => {
  return (
    <svg
      {...{ onClick, className }}
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.97655 5.2578H0.28125C0.12593 5.2578 0 5.13187 0 4.97655V0.28125C0 0.12593 0.12593 0 0.28125 0H1.40625C1.56157 0 1.6875 0.12593 1.6875 0.28125V2.112C2.7603 0.920602 4.31859 0.175078 6.05098 0.187664C9.25971 0.210961 11.8271 2.80383 11.8203 6.01263C11.8135 9.21698 9.21375 11.8125 6.00781 11.8125C4.50572 11.8125 3.1368 11.2427 2.10523 10.3075C1.98572 10.1991 1.98021 10.0132 2.09428 9.89918L2.89038 9.10308C2.99524 8.99822 3.16371 8.99252 3.27478 9.09077C4.00219 9.73444 4.95891 10.125 6.00781 10.125C8.28752 10.125 10.1328 8.28009 10.1328 6C10.1328 3.7203 8.2879 1.875 6.00781 1.875C4.63681 1.875 3.42312 2.54241 2.67326 3.5703H4.97655C5.13188 3.5703 5.2578 3.69623 5.2578 3.85155V4.97655C5.2578 5.13187 5.13188 5.2578 4.97655 5.2578V5.2578Z"
        fill="#DDE6F3"
      />
    </svg>
  );
};
