import { useEffect, useMemo, useState } from 'react';
const _ = require('lodash');
import { LabelObject } from '../types';
import { MULTIPLE_LABELS, MUTUALLY_EXCLUSIVE } from './constants';

export const useCustomCategories = (data: LabelObject[], category) => {
  const isNew = category.category === 'custom';
  const [stage, setStage] = useState(isNew ? 0 : 1);
  const [ccc_type, setCCCType] = useState(category.ccc_type);
  const [dropdownOptions, setDropdownOptions] = useState([]);

  useEffect(() => {
    if (data) {
      setDropdownOptions(() => {
        return data.map(({ name, value }) => {
          return {
            label: name,
            value,
          };
        });
      });
    }
  }, [data]);

  useEffect(() => {
    setStage(isNew ? 0 : 1);
    setCCCType(category.ccc_type);
  }, [category.category]);

  const next = () =>
    setStage((prev) => {
      if (prev < 2) return prev + 1;
    });
  const prev = () =>
    setStage((prev) => {
      if (prev > 0) return prev - 1;
    });

  return [stage, next, prev, dropdownOptions, ccc_type, setCCCType, isNew];
};

const updateVisualAssets = (
  currentVisual,
  predictedAsset,
  labelKey,
  updateFields
) => {
  currentVisual.asset.forEach((v, i) => {
    const matchFrame =
      v.frame_num === predictedAsset.frame_num ||
      v.frame_num?.toString() === predictedAsset.frame_num?.toString();
    const matchUrl = v.url === predictedAsset.url;
    const isVideo = currentVisual.type?.toLowerCase() === 'video';
    const isImage = currentVisual.type?.toLowerCase() === 'image';

    if ((isVideo && (matchFrame || matchUrl)) || isImage) {
      Object.assign(visuals[labelKey].asset[i], updateFields);
    }
  });
};

const processPredictedVisuals = (inputType, visuals, predictedVisuals) => {
  Object.entries(visuals).forEach(([visual_hash, val]) => {
    if (!predictedVisuals || !predictedVisuals[visual_hash]) return;

    const currentP = predictedVisuals[visual_hash];
    const currentV = visuals[visual_hash];

    currentP.asset.forEach((p) => {
      const updateFields =
        inputType === MUTUALLY_EXCLUSIVE
          ? { labels: p.labelName, confidence: p.confidence }
          : { labels: p.labels };

      updateVisualAssets(currentV, p, visual_hash, updateFields);
    });

    Object.assign(visuals[visual_hash], { spend: val.spend });
  });
};

const calculateLabelCounter = (
  inputType: string,
  labelOptions: string[],
  annotatedVisuals
) => {
  let labelCounter = {};
  let isValid = true;

  console.log({ inputType, labelOptions, annotatedVisuals });

  if (inputType === MUTUALLY_EXCLUSIVE) {
    labelOptions.forEach((label) => {
      const count = getLabelCount(label, annotatedVisuals, inputType);

      labelCounter[label] = {
        label,
        count,
      };
    });

    const numAnnotated = Object.entries(labelCounter).reduce(
      (total, [key, val]) => (val.count >= 2 ? (total += 1) : total),
      0
    );

    if (numAnnotated < 2) isValid = false;
  } else if (inputType === MULTIPLE_LABELS) {
    let numAnnotated = 0;

    labelOptions.forEach((label) => {
      const yesLabel = `${label} - Yes`;
      const noLabel = `${label} - No`;

      let yesCount = 0;
      let noCount = 0;

      Object.entries(annotatedVisuals).forEach(([key, val]) => {
        val.asset.forEach((frame) => {
          if (!frame.labels) return;
          Object.entries(frame.labels).forEach(([labelName, opt]) => {
            let annotation = opt?.status || opt || null;
            const hasPrediction = !!opt?.confidence;

            if (hasPrediction) annotation = null;

            if (labelName === label && annotation === 'yes') {
              yesCount += 1;
            }
            if (labelName === label && annotation === 'no') {
              noCount += 1;
            }
          });
        });
      });

      labelCounter[yesLabel] = {
        label: yesLabel,
        count: yesCount,
      };
      labelCounter[noLabel] = {
        label: noLabel,
        count: noCount,
      };
    });

    let labelArr = [];

    for (const key in labelCounter) {
      labelArr.push(
        Object.assign(labelCounter[key], { name: key.split(' - ')[0] })
      );
    }

    const grouped = _.mapValues(_.groupBy(labelArr, 'name'));
    Object.keys(grouped).forEach((key) => {
      if (grouped[key][0]['count'] >= 2 && grouped[key][1]['count'] >= 2) {
        numAnnotated += 1;
      }
    });

    if (numAnnotated < 1) isValid = false;
  }

  return { labelCounter, isValid };
};

export const useCustomCategoriesEditor = (
  input,
  annotatedVisuals,
  predictedVisuals,
  visualsData,
  labelOptions
) => {
  const visuals = useMemo(
    () => (visualsData ? { ...visualsData } : {}),
    [visualsData]
  );

  try {
    processPredictedVisuals(input.type, visuals, predictedVisuals);
  } catch (e) {
    console.error(e);
  }

  const { labelCounter, isValid } = useMemo(
    () => calculateLabelCounter(input.type, labelOptions, annotatedVisuals),
    [input.type, labelOptions, annotatedVisuals]
  );

  return [visuals, labelCounter, isValid];
};

const getLabelCount = (label, annotatedVisuals, type) => {
  let count = 0;

  if (type === MUTUALLY_EXCLUSIVE) {
    Object.entries(annotatedVisuals).forEach(([key, val]) => {
      if (!Array.isArray(val.asset)) return;
      val.asset.forEach((frame) => {
        if (frame.labels === label) count += 1;
      });
    });
  } else if (type === MULTIPLE_LABELS) {
    Object.entries(annotatedVisuals).forEach(([key, val]) => {
      Object.entries(val.labels).forEach(([labelName, opt]) => {
        if (labelName === label && opt === 'yes') {
          count += 1;
        }
      });
    });
  }

  return count;
};
