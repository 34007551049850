import * as React from 'react';
import Overlay from '../../../components/Overlay';
import { ErrorBoundary } from '../../../components/Helpers/Error';

import { LabelObject } from '../types';
import { EDITABLE_CATEGORIES, STANDARD_CATEGORIES } from '../categories';

import Custom from './Custom/Custom';
import CustomSingle from './Custom/CustomSingle';

import './CategoryBuilder.scss';
import { useCreativeAnalyticsGet, useCreativeAnalyticsSet } from '../contexts';

type CategoryBuilderProps = {
  data: LabelObject[];
  layout: string;
  category: any;
  editCategoryVisual: any;
  fetchPerformanceData: any;
  getUdcApi: any;
  udcApi: any;
  derivedLabelsApi: any;
};

const CategoryBuilder = ({
  data,
  category = {
    category: '',
    category_id: '',
    ccc_type: '',
  },
  editCategoryVisual,
  fetchPerformanceData,
  getUdcApi,
  udcApi,
  derivedLabelsApi,
  getDerivedLabelsApi,
  allKeywords,
  getAdNameLabelsApi,
  adNameLabelsApi,
  singleToFull,
  showDerivativeFlow,
}: CategoryBuilderProps) => {
  const udcData = getUdcApi ? getUdcApi.data : [];

  if (editCategoryVisual && editCategoryVisual.show) {
    if (editCategoryVisual.type === 'custom') {
      return (
        // <Overlay show close={() => setEditCategoryVisual(null)}>
        <CustomSingle
          data={data}
          category={editCategoryVisual.category}
          category_id={editCategoryVisual.category_id || ''}
          visual={editCategoryVisual.visual_hash}
          fetchPerformanceData={fetchPerformanceData}
          singleToFull={singleToFull}
          easy_ai={editCategoryVisual?.easy_ai}
          
        />
        // </Overlay>
      );
    }
  }

  let Comp = Custom;

  return (
    <ErrorBoundary
      fallback={
        <div className="category-manager category-manager--loading">
          <p className="category-manager--loading__msg">There was an error.</p>
        </div>
      }
    >
      <Comp
        data={data}
        category={category}
        fetchPerformanceData={fetchPerformanceData}
        udcData={udcData}
        udcApi={udcApi}
        derivedLabelsApi={derivedLabelsApi}
        getDerivedLabelsApi={getDerivedLabelsApi}
        allKeywords={allKeywords}
        getAdNameLabelsApi={getAdNameLabelsApi}
        adNameLabelsApi={adNameLabelsApi}
        showDerivativeFlow={showDerivativeFlow}
        getUdcApi={getUdcApi}
      />
    </ErrorBoundary>
  );
};

const Container = ({
  category = { category: '', ccc_type: '', category_id: '' },
  editCategoryVisual,
  singleToFull,
  showDerivativeFlow = false,
  udcApi,
  allKeywords,
  getAdNameLabelsApi,
  adNameLabelsApi,
  setEditCategory,
  setEditCategoryVisual,
}) => {
  const {
    data,
    fetchPerformanceData,
    getUdcApi,
    derivedLabelsApi,
    getDerivedLabelsApi,
  } = useCreativeAnalyticsGet();

  console.log({
    udcApi,
    allKeywords,
    getAdNameLabelsApi,
    adNameLabelsApi,
    setEditCategory,
    setEditCategoryVisual,
  });

  return (
    <CategoryBuilder
      {...{
        data,
        category,
        editCategoryVisual,
        fetchPerformanceData,
        getUdcApi,
        udcApi,
        derivedLabelsApi,
        getDerivedLabelsApi,
        allKeywords,
        getAdNameLabelsApi,
        adNameLabelsApi,
        setEditCategory,
        setEditCategoryVisual,
        singleToFull,
        showDerivativeFlow,
      }}
    />
  );
};

export default Container;
