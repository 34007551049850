import React, { useRef, useEffect } from 'react';
import { sortReportCardCategories } from '../utils';

import './CategoriesList.scss';
import { CategoriesLookupItem, VisualsLookupItem } from '../types';
import { LabelObject, VisualObject } from '../../types';
import { CompareIcon, StarIcon } from '../icons';
import Tooltip from '../../../../components/Tooltip';
import { useCreativeAnalyticsGet } from '../../contexts';
import Tippy from '@tippyjs/react';
import CategoryDetails from '../CategoryDetails';
import { CATEGORIES } from '../constants';
import { useIsDirectMail, useLabelsById } from '../../selectors';

type CategoriesList = {
  current: VisualsLookupItem;
  categoryHovered: boolean;
  setCategoryHovered: (value: string | null) => void;
  setShowCategoryDetails: (value: string) => void;
  fromCreativeStudio: boolean;
};

const CategoriesList = ({
  bestPerforming,
  isRoas,
  current,
  categories,
  categoryHovered,
  setCategoryHovered,
  showCategoryDetails,
  setShowCategoryDetails,
  fromCreativeStudio,
  activePage,
  getLabelsById,
}) => {
  const ref = useRef(null);

  useEffect(() => {
    if (showCategoryDetails) {
      ref.current.scrollIntoView();
    }
  }, [showCategoryDetails]);

  const doNotInclude = [
    'Top 10 Spending Visuals',
    'All Visuals',
    'All New Visuals',
    'Audience',
  ];

  return (
    <div
      className={`categories card ${activePage === CATEGORIES ? 'show' : ''}`}
      ref={ref}
    >
      <ul className={`categories-list ${showCategoryDetails ? 'hide' : ''}`}>
        {categories
          .filter((category) => !doNotInclude.includes(category.category))
          .map((category: CategoriesLookupItem) => {
            return (
              <CategoryBlock
                {...{
                  current,
                  category,
                  setCategoryHovered,
                  setShowCategoryDetails,
                  fromCreativeStudio,
                  bestPerforming,
                  getLabelsById,
                }}
                active={categoryHovered === category.category}
              />
            );
          })}
      </ul>
      <div className={`category-details ${showCategoryDetails ? 'show' : ''}`}>
        <CategoryDetails
          {...{
            current,
            showCategoryDetails,
            setShowCategoryDetails,
            fromCreativeStudio,
            isRoas,
          }}
        />
      </div>
    </div>
  );
};

export default CategoriesList;

type CategoryBlockProps = {
  current: VisualsLookupItem;
  category: CategoriesLookupItem;
  setCategoryHovered: (value: string | null) => void;
  active: boolean;
  setShowCategoryDetails: (value: string) => void;
  fromCreativeStudio: boolean;
  bestPerforming: boolean;
};

const CategoryBlock = ({
  current,
  category,
  active,
  setShowCategoryDetails,
  fromCreativeStudio = false,
  bestPerforming,
  getLabelsById,
}: CategoryBlockProps) => {
  const isDirectMail = useIsDirectMail();
  const labels = getLabelsById(category.labels.map((label) => label.id) || []);

  const categoryLabels: LabelObject[] = getCategoryLabels(
    current,
    labels,
    fromCreativeStudio,
    bestPerforming
  );
  const handleClick = (e: any) => {
    console.log(category);
    setShowCategoryDetails(category.category);
  };

  const hasTestingOpportunities = category.labels.some(
    (label) => label.total_results === 0 && label.spend === 0
  );

  const hasIndexData = category.labels.some((label) => {
    return label.average_cpa_quintile || label.average_percent_spend;
  });

  return  categoryLabels.length ? (
    <li className="categories-list__item">
      <div
        onClick={handleClick}
        className={`categories-list__block ${
          active ? 'categories-list__block--active' : ''
        }`}
      >
        <div className="categories-list__category">
          <div className="category">
            <span className="name">{category.category}</span>
            {hasTestingOpportunities && (
              <Tooltip
                content="Testing opportunities available"
                delay={[500, 0]}
              >
                <span className="icon">
                  <StarIcon />
                </span>
              </Tooltip>
            )}
            {hasIndexData && !isDirectMail && (
              <Tooltip content="Darwin Index data available" delay={[500, 0]}>
                <span className="icon">
                  <CompareIcon />
                </span>
              </Tooltip>
            )}
          </div>
          {categoryLabels.length ? <div>Rank</div> : null}
        </div>
        {categoryLabels.length ? (
          categoryLabels.map(
            ({
              categoryLabel,
              rank,
            }: {
              categoryLabel: LabelObject;
              rank: number;
            }) => (
              <div className="categories-list__label">
                <div className="name">{categoryLabel.name}</div>
                <div className="ranking">
                  <span className="rank">{rank + 1}/</span>
                  <span className="total">{category.labels.length}</span>
                </div>
              </div>
            )
          )
        ) : (
          <h3 className="categories-list__label">N/A</h3>
        )}
      </div>
    </li>
  ) : null;
};

export const getCategoryLabels = (
  current,
  labels = [],
  fromCreativeStudio,
  bestPerforming
) => {
  let categoryLabels = [];

  if (!labels) return [];

  if (!bestPerforming) {
    if (!fromCreativeStudio && !current.labels) {
      labels.forEach((label: LabelObject, i: number) => {
        if (
          label.visuals.some((visual: VisualObject) => visual.id === current.id)
        ) {
          categoryLabels.push({
            categoryLabel: label,
            rank: i,
          });
        }
      });
    } else {
      labels.forEach((label: LabelObject, i: number) => {
        if (current.label_ids.includes(label.id)) {
          categoryLabels.push({
            categoryLabel: label,
            rank: i,
          });
        }
      });
    }
  } else {
    labels.forEach((label: LabelObject, i: number) => {
      if (i === 0) {
        categoryLabels.push({
          categoryLabel: label,
          rank: i,
        });
      }
    });
  }

  return categoryLabels;
};
