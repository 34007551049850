import React, { useState } from 'react';
import { useCopyToClipboard } from '../../hooks/useCopyToClipboard';
import { CopyIcon } from '../../features/CreativeAnalytics/TryThese/assets';

export const CopyableText = ({ children }) => {
  const { isCopied, copyToClipboard } = useCopyToClipboard();
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        cursor: 'pointer',
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <span style={{ marginRight: '8px' }}>{children}</span>
      {isHovered && (
        <CopyIcon
          style={{
            fontSize: '16px',
            color: isCopied ? 'green' : 'gray',
            display: 'inline',
          }}
          copy={() => copyToClipboard(children)}
        />
      )}
      {isCopied && (
        <span
          style={{
            position: 'absolute',
            top: '100%',
            left: '50%',
            transform: 'translateX(-50%)',
            marginTop: '4px',
            backgroundColor: 'black',
            color: 'white',
            padding: '4px 8px',
            borderRadius: '4px',
            fontSize: '12px',
          }}
        >
          Copied!
        </span>
      )}
    </div>
  );
};
