import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import './PresetsTabs.scss';
import Presets from '../Presets';
import { usePermissions } from '../../../components/Permissions/usePermissions';
import { PRESETS } from '../../../components/Permissions/constants';
import { useCreativeAnalyticsGet } from '../contexts';
import { usePresetsData } from '../Presets/selectors';
import { getLabelsLookup } from '../Presets/utils';
import { useSearchParams } from '../../../components/Hooks/search-params';
import { useSelector } from 'react-redux';
import { setDefaultPrimaryMetric } from '../hooks/useUrlParams';
import { useLabelsLookup } from '../selectors';

const maybeUseDefaultMetric = (value: string, defaultMetric: string) => {
  if (!value?.length) return defaultMetric;
  return value;
};

const useCheckIfPresetIsSelected = () => {
  const { default_primary_metric, isROAS } = useSelector(
    ({ ViewSettings: { default_primary_metric, isROAS } }) => ({
      default_primary_metric,
      isROAS,
    })
  );

  const primary_metric = setDefaultPrimaryMetric(
    default_primary_metric,
    isROAS
  );

  return (preset) => {
    const currentSearchParams = new URLSearchParams(window.location.search);
    const presetSearchParams = new URLSearchParams(preset.url);

    const paramsToCheck = ['primary_metric'];

    const allParamsMatch = paramsToCheck.every((param) => {
      const currentValue = currentSearchParams.get(param);
      const presetValue = presetSearchParams.get(param);

      if (param === 'primary_metric') {
        return (
          maybeUseDefaultMetric(
            decodeURIComponent(currentValue || '').toLowerCase(),
            primary_metric
          ) ===
          maybeUseDefaultMetric(
            decodeURIComponent(presetValue || '').toLowerCase(),
            primary_metric
          )
        );
      }

      return (
        decodeURIComponent(currentValue || '').toLowerCase() ===
        decodeURIComponent(presetValue || '').toLowerCase()
      );
    });

    // Additional checks for `labels` and `udc_labels` to allow matching in either direction
    const labelsMatch = (() => {
      const currentLabels = currentSearchParams.get('labels');
      const currentUdcLabels = currentSearchParams.get('udc_labels');
      const presetLabels = presetSearchParams.get('labels');
      const presetUdcLabels = presetSearchParams.get('udc_labels');

      return (
        (currentLabels && currentLabels === presetUdcLabels) ||
        (currentUdcLabels && currentUdcLabels === presetLabels) ||
        (currentLabels && currentLabels === presetLabels) ||
        (currentUdcLabels && currentUdcLabels === presetUdcLabels)
      );
    })();

    return allParamsMatch && labelsMatch;
  };
};

const checkIfNoneSelected = () => {
  const urlParams = new URLSearchParams(window.location.search);

  const labels = urlParams.get('labels');
  const udcLabels = urlParams.get('udc_labels');

  if (
    (!labels || labels.trim() === '') &&
    (!udcLabels || udcLabels.trim() === '')
  ) {
    return true;
  }

  return false;
};

export const PresetsTabs = () => {
  const presetsData: [] = usePresetsData();
  const [shouldDisableTabs, setShouldDisableTabs] = useState(false);
  const { isOrganicView, loading } = useCreativeAnalyticsGet();
  const hasPermission = usePermissions();
  const selectPreset = useSelectPreset();
  const noPresetSelected = checkIfNoneSelected();
  const checkIfPresetIsSelected = useCheckIfPresetIsSelected();

  return (
    <div className="presets-bar" style={{ background: '#EFF5FD' }}>
      {!isOrganicView && hasPermission(PRESETS) && (
        <div className="mr-2">
          <Presets />
        </div>
      )}
      <ul>
        <Tab
          disabled={loading}
          selected={noPresetSelected}
          handleSelect={(id) => {
            // setSelectedPresetId(id);
            selectPreset('');
            setShouldDisableTabs(true);
          }}
        >
          All Visuals
        </Tab>

        {presetsData.map((preset) => (
          <Tab
            disabled={loading}
            preset={preset}
            selected={checkIfPresetIsSelected(preset)}
            handleSelect={(id) => {
              // setSelectedPresetId(id);
              selectPreset(preset.url);
              setShouldDisableTabs(true);
            }}
          >
            {preset.preset_name}
          </Tab>
        ))}
      </ul>
    </div>
  );
};

const deriveLabelsArray = (param) => {
  return param.split(',').reduce((arr, l) => {
    let label = l.includes('->') ? l.split('->')[1] : l;
    let category = l.includes('->') ? l.split('->')[0] : null;
    if (l === 'top_10_spending') label = 'Top 10 Spending Visuals';

    arr.push(`${category}->${label}`);

    return arr;
  }, []);
};

export const useSelectPreset = () => {
  const labelsLookup = useLabelsLookup();
  const { view_id, isROAS } = useSelector((state) => ({
    view_id: state.ViewSettings.viewId,
    isROAS: state.ViewSettings.isROAS,
  }));

  const { setParams: setSearchParams } = useSearchParams();

  const handleRoute = (url) => {
    const withoutPrefix = url.includes(view_id)
      ? url.split(`${view_id}/`)[1]
      : url;
    const withPrefix = `https://dashboard.darwinsoftware.io/${withoutPrefix}`;

    const newUrl = new URL(withPrefix);
    const args = new URLSearchParams(newUrl.search);
    const primary_metric = args.get('primary_metric');
    const labelsParam = args.get('labels');
    const udcLabelsParam = args.get('udc_labels');
    const cpaMetric = isROAS ? 'ROAS' : 'CPA';

    let udc_labels = [];
    let labels = [];

    if (labelsParam?.length) {
      labels = deriveLabelsArray(labelsParam);
    }

    if (udcLabelsParam?.length) {
      udc_labels = deriveLabelsArray(udcLabelsParam);
    }

    const updatedLabels = [];

    labels.forEach((labelId) => {
      const labelDetails = labelsLookup.get(labelId);

      if (labelDetails?.udc && !udc_labels.length) {
        udc_labels.push(labelId);
      } else {
        updatedLabels.push(labelId);
      }
    });

    labels = updatedLabels;

    setSearchParams({
      primary_metric: primary_metric || cpaMetric,
      labels,
      udc_labels,
    });
  };

  return handleRoute;
};

const Tab = ({ selected, preset, disabled, handleSelect, children }) => {
  return (
    <button
      onClick={() => {
        if (!disabled) {
          handleSelect(preset?._id ?? '');
        }
      }}
      className={clsx('presets-tab', {
        'presets-tab--is-selected': selected,
        'presets-tab--is-disabled': disabled,
      })}
    >
      {children}
    </button>
  );
};
