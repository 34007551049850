import React, { useMemo } from 'react';
import { useCreativeAnalyticsGet } from '../../contexts';
import { DERIVED_LABELS } from '../constants';
import { getCategoryOverview } from '../utils/getCategoryOverview';

function extractDependencies(derived_labels) {
  const dependencies = new Map();

  derived_labels.forEach(({ label_name, label_value, rules }) => {
    rules.forEach(({ labels_to_exclude, labels_to_include }) => {
      [labels_to_exclude, labels_to_include].forEach((labels, index) => {
        const dependency_type = index === 0 ? 'exclude' : 'include';
        labels.forEach(({ category, name, value, label }) => {
          if (!dependencies.has(category)) {
            dependencies.set(category, []);
          }
          let dependents = dependencies.get(category)?.dependents || [];

          if (
            !dependents?.some(
              (dependent) => dependent.label_value === label_value
            )
          ) {
            dependents.push({ label_name, label_value });
          }

          dependencies
            .get(category)
            .push({ name, value, label, dependency_type, dependents });
        });
      });
    });
  });

  return dependencies;
}

const findDependenciesForCategory = (category_name, category_structure) => {
  let deps = [];

  if (category_structure) {
    category_structure.forEach((existingCategory) => {
      existingCategory.derived_labels.forEach((derived_label) => {
        derived_label.rules.forEach((rule) => {
          const ruleLabels = [
            ...rule.labels_to_include,
            ...rule.labels_to_exclude,
          ];
          ruleLabels.forEach((ruleLabel) => {
            if (ruleLabel.category === category_name) {
              deps.push({
                derived_from: ruleLabel.name,
                category: existingCategory.category_name,
                derived_label: derived_label.label_name,
                derived_category: existingCategory.category_name,
              });
            }
          });
        });
      });
    });
  }

  return deps;
};

export const useDependencies = ({ category_name, ccc_type }) => {
  const { getDerivedLabelsApi } = useCreativeAnalyticsGet();
  const category_structure = getDerivedLabelsApi.get.data?.category_structure;
  const currentDerivedCategory =
    category_structure?.find((x) => x.category_name === category_name) || null;
  const isDerived = ccc_type == DERIVED_LABELS;

  const dependencies = useMemo(() => {
    if (!currentDerivedCategory || !isDerived) return null;
    return extractDependencies(currentDerivedCategory?.derived_labels);
  }, [category_name, category_structure]);

  const filteredDependencies =
    isDerived && dependencies
      ? new Map( // dont show edit btn in certain cases
          [...dependencies.entries()].filter(([category, arr]) => {
            const specialCondition = getCategoryOverview({
              category_name: category,
            });
            return !specialCondition;
          })
        )
      : null;

  const dependents = useMemo(() => {
    return findDependenciesForCategory(category_name, category_structure);
  }, [category_name, category_structure]);

  return { dependencies, filteredDependencies, dependents };
};
