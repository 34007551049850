import React from 'react';
import { usePostRequest } from '../../../../../components/Hooks/usePostRequest';
import { ApiStateType } from '../../../../../types/types';
import { useDarwinClientId } from '../../selectors';

export const useVertexApi = () => {
  const darwin_client_id = useDarwinClientId();

  const createApi: ApiStateType = usePostRequest(
    [
      'api',
      'creative-analytics',
      'custom-categories',
      'create-mm-category',
      darwin_client_id,
    ].join('/')
  );

  const editApi: ApiStateType = usePostRequest(
    [
      'api',
      'creative-analytics',
      'custom-categories',
      'edit-mm-category',
      darwin_client_id,
    ].join('/')
  );
  const deleteApi: ApiStateType = usePostRequest(
    [
      'api',
      'creative-analytics',
      'custom-categories',
      'delete-mm-category',
      darwin_client_id,
    ].join('/')
  );

  return {
    create: createApi,
    edit: editApi,
    delete: deleteApi,
  };
};

export const MmSaveSuccess = ({ refresh, name }) => {
  return (
    <div className="toast__content">
      <h3 className="toast__title">{name}</h3>
      <h4 className="toast__subtitle">
        Category saved - perform a hard refresh to see results. (Categories may
        take several minutes to update)
      </h4>
    </div>
  );
};

export const MmDeleteSuccess = ({ refresh, name }) => {
  return (
    <div className="toast__content">
      <h3 className="toast__title">{name}</h3>
      <h4 className="toast__subtitle">Category deleted. </h4>
    </div>
  );
};
