import React, { useReducer } from 'react';
import { DataState, RulesManagerAction } from '../types';
import { EMPTY_RULE, initialDataState } from '../constants';

export const SET_RULES = 'SET_RULES';
export const SET_RULE_INDEX = 'SET_RULE_INDEX';
export const SET_ACTIVE_RULE = 'SET_ACTIVE_RULE';
export const SET_CATEGORY_A = 'SET_CATEGORY_A';
export const SET_LABEL_A = 'SET_LABEL_A';
export const SET_CATEGORY_B = 'SET_CATEGORY_B';
export const SET_LABEL_B = 'SET_LABEL_B';
export const SET_EXPLANATION = 'SET_EXPLANATION';
export const ADD_RULE = 'ADD_RULE';
export const REMOVE_RULE = 'REMOVE_RULE';

export const rulesManagerReducer = (
  state: DataState,
  action: RulesManagerAction
): DataState => {
  switch (action.type) {
    case SET_RULES:
      return {
        ...state,
        rules: action.rules,
      };

    case SET_RULE_INDEX:
      return {
        ...state,
        activeRuleIndex: action.ruleIndex,
      };

    case SET_CATEGORY_A:
      return {
        ...state,
        rules: state.rules.map((row, i) =>
          i === action.ruleIndex ? { ...row, category_a: action.value } : row
        ),
      };

    case SET_LABEL_A:
      return {
        ...state,
        rules: state.rules.map((row, i) =>
          i === action.ruleIndex ? { ...row, label_a: action.value } : row
        ),
      };

    case SET_CATEGORY_B:
      return {
        ...state,
        rules: state.rules.map((row, i) =>
          i === action.ruleIndex ? { ...row, category_b: action.value } : row
        ),
      };

    case SET_LABEL_B:
      return {
        ...state,
        rules: state.rules.map((row, i) =>
          i === action.ruleIndex ? { ...row, label_b: action.value } : row
        ),
      };

    case SET_EXPLANATION:
      return {
        ...state,
        rules: state.rules.map((row, i) =>
          i === action.ruleIndex ? { ...row, explanation: action.value } : row
        ),
      };

    case ADD_RULE:
      return {
        ...state,
        rules: [EMPTY_RULE, ...state.rules],
      };

    case REMOVE_RULE:
      return {
        ...state,
        rules: state.rules.filter((_, i) => i !== action.ruleIndex),
      };
  }
};

export const useRulesManagerState = () => {
  const [rulesManagerState, rulesManagerDispatch] = useReducer(
    rulesManagerReducer,
    initialDataState
  );

  return { rulesManagerState, rulesManagerDispatch };
};
