import { useEffect, useState } from 'react';
import { usePostRequest } from '../../../components/Hooks/usePostRequest';
import { darwinDateToDate } from '../../../utils/darwin-dates';
import { useSelector } from 'react-redux';

function replacePeriodsInKeys(obj) {
  return Object.keys(obj).reduce((acc, key) => {
    const newKey = key.replace(/\./g, '___'); // Replace all periods with ___
    acc[newKey] = obj[key]; // Set the value with the new key in the accumulator
    return acc;
  }, {});
}

const replaceInvalidCharacters = (str) => {
  if (!str) return str;
  return str.replaceAll('.', '___');
};

export const useShareReportCard = ({
  visual_hash,
  start_date,
  end_date,
  copilotData,
  tableData,
  columns,
}) => {
  const { isDirectMail, isROAS, filteredData, visuals } = useSelector(
    ({
      ViewSettings: {
        customIntegratedMetrics,
        custom_events,
        additional_metrics,
        isOrganicView,
        isDirectMail,
        isEmailView,
        ga_enabled,
        isROAS,
      },
      CreativeAnalytics: { dnaReportData, hiddenLabels, visuals, labels },
    }) => {
      const ids = visuals.ids.filter((id) => id === visual_hash);
      return {
        customIntegratedMetrics,
        custom_events,
        additional_metrics,
        isOrganicView,
        isDirectMail,
        isEmailView,
        ga_enabled,
        isROAS,
        metadataFields: dnaReportData?.categories || [],
        hiddenLabels,
        visuals: ids.map((id) => visuals.lookup.get(id)),
        filteredData: Array.from(labels.lookup.values()),
      };
    }
  );
  const {
    data: apiData,
    loading,
    error,
    request,
  } = usePostRequest(
    ['api', 'creative-analytics', 'create-static-report'].join('/')
  );
  const [shouldShowShareModal, setShouldShowShareModal] = useState(false);
  const [sharedReportId, setSharedReportId] = useState(null);

  const shareStaticReportCard = ({ report_name, report_description }) => {
    const payload = {
      report_name,
      report_description,
      start_date,
      end_date,
      data: {
        report_type: 'report_card',
        isDirectMail,
        isROAS,
        visual_hash,
        copilotData: copilotData || null,
        // tableData,
        columns,
        labels: filteredData.map((label) => {
          let update = {
            ...label,
            winners: label.winner_ids,
            losers: label.loser_ids,
            visuals: label.visual_ids,
          };

          Object.keys(label).forEach((key) => {
            if (key.includes('.')) {
              update[replaceInvalidCharacters(key)] = label[key];
              delete update[key];
            }
          });

          return update;
        }),
        visuals: visuals.map((visual) => {
          let update = {};

          Object.keys(visual).forEach((key) => {
            update[replaceInvalidCharacters(key)] = visual[key];
          });

          return update;
        }),
      },
    };

    console.log(payload);
    request(payload);
  };

  useEffect(() => {
    const report_id = extractReportId(apiData);

    if (report_id) {
      setSharedReportId(report_id);
    }
  }, [apiData]);

  const handleShareReportCard = () => {
    setShouldShowShareModal(true);
  };

  return {
    sharedReportId,
    handleShareReportCard,
    shouldShowShareModal,
    closeShareModal: () => {
      setSharedReportId(null);
      setShouldShowShareModal(false);
    },
    submitShareReportCard: ({ report_name, report_description }) =>
      shareStaticReportCard({
        report_name,
        report_description,
      }),
    loadingShareReportCard: loading,
  };
};

const extractReportId = (apiData) => {
  if (apiData?.message) {
    const reportIdMatch = apiData.message.match(/report id (\w+)/);

    const reportId = reportIdMatch ? reportIdMatch[1] : null;

    return reportId;
  } else {
    return null;
  }
};
