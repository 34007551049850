import React, { useEffect, useRef, useState } from 'react';
import CombinationsCard from './Card';
import Tooltip from '../../../../components/Tooltip';
import RulesManagerToggle from '../../RulesManager/Toggle';

const CombinationsCarousel = ({
  combinations,
  resetFilters,
  handleHideCategory,
  handleHideLabel,
  getRecoRulesLoading,
  deleteRecoRulesLoading,
}) => {
  const ref = useRef();
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const [slideIndex, setSlideIndex] = useState(0);
  const [slide, setSlide] = useState(null);

  useEffect(() => {
    if (combinations.length > 0 && slideIndex >= combinations.length) {
      setSlideIndex(combinations.length - 1);
    }
  }, [combinations]);

  useEffect(() => {
    const found = combinations.find((c, i) => {
      if (i === slideIndex) return c;
    });

    if (found) setSlide(found);
  }, [combinations, slideIndex]);

  return (
    <div ref={ref} className="combinations-carousel">
      <div className="d-flex">
        <RulesManagerToggle />
        {(getRecoRulesLoading || deleteRecoRulesLoading) && (
          <div className="d-flex align-items-center justify-content-center ml-3">
            <i className="fas fa-spinner fa-spin" style={{ opacity: 0.6 }} />
          </div>
        )}
      </div>
      <Tooltip content="Reset hidden categories/labels">
        <div className="reset" onClick={resetFilters}>
          <i className="fa-solid fa-arrow-rotate-left" />
        </div>
      </Tooltip>
      {combinations.length > 1 ? (
        <div className="d-flex justify-content-center align-items-center combinations-carousel__nav-container">
          <div className="combinations-carousel__nav">
            <div
              className="combinations-carousel__arrow"
              onClick={() => setSlideIndex(0)}
            >
              <i className="fa-solid fa-angles-left"></i>
            </div>
            <div
              ref={prevRef}
              className="combinations-carousel__arrow combinations-carousel__prev"
              onClick={() => {
                if (slideIndex === 0) return;
                setSlideIndex((prev) => prev - 1);
              }}
            >
              <i className="fa-solid fa-angle-left"></i>
            </div>
            <div className="px-2">
              {slideIndex + 1} of {combinations.length}
            </div>
            <div
              ref={nextRef}
              className="combinations-carousel__arrow combinations-carousel__next"
              onClick={() => {
                if (slideIndex === combinations.length - 1) return;
                setSlideIndex((prev) => prev + 1);
              }}
            >
              <i className="fa-solid fa-angle-right"></i>
            </div>
            <div
              className="combinations-carousel__arrow"
              onClick={() => setSlideIndex(combinations.length - 1)}
            >
              <i className="fa-solid fa-angles-right"></i>
            </div>
          </div>
        </div>
      ) : null}
      {slide && (
        <CombinationsCard
          data={slide}
          {...{ handleHideCategory, handleHideLabel }}
        />
      )}
    </div>
  );
};

export default CombinationsCarousel;
