export const SET_ERROR = '[UI] SET_ERROR';

export const setError = (entity, hasError) => ({
  type: SET_ERROR,
  hasError,
  meta: { entity },
});

// for use in redux toolkit reducer
export const setErrorState = (state, entity, error) => {
  return state.Errors[entity] = error;
};
