import { DataState, Rule } from './types';

export const EMPTY_RULE: Rule = {
  rule_id: '',
  category_a: '',
  label_a: '',
  category_b: '',
  label_b: '',
  explanation: '',
};

export const initialDataState: DataState = {
  rules: [],
  activeRuleIndex: 0,
};
