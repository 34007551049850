export const BREAKDOWN_OPTIONS = [
  {
    label: 'Title Copy Performance',
    value: 'title_asset',
  },
  // {
  //   label: 'Description Copy Performance',
  //   value: 'description_asset',
  // },
  {
    label: 'Body Copy Performance',
    value: 'body_asset',
  },
  {
    label: 'Call to Action Copy Performance',
    value: 'call_to_action_asset',
  },
];