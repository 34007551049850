import { FORMAT, PRIMARY_DIMENSIONS, PERCENTAGE_OF_TEXT_IN_VISUAL, PRIMARY_PHRASE_FONT_SIZE, PRIMARY_PHRASE_POSITION, SECONDARY_PHRASE_POSITION, WORD_COUNT, PEOPLE_VS_NO_PEOPLE, NUMBER_OF_PEOPLE, PEOPLE_TRAITS } from "../categories";

export const MUTUALLY_EXCLUSIVE = 'mutually_exclusive';
export const MULTIPLE_LABELS = 'multiple_labels';
export const EASY_AI = 'easy_ai';

export const COMPLIANCE_LABELS = ['Yes - Compliant', 'No - Not Compliant'];

export const INVALID_CHARACTERS = ['_', '&', '$', '@', '=', ';', ':', '/', '+', '?', '{', '}', '%', '`', ']', '[', '"', '~', '#', '|' ,'<', '>', '__', '\\', '^', ','];

export const STANDARD_CATEGORY_TYPES = {
  [FORMAT]: MUTUALLY_EXCLUSIVE,
  [PRIMARY_DIMENSIONS]: MUTUALLY_EXCLUSIVE,
  [PERCENTAGE_OF_TEXT_IN_VISUAL]: MUTUALLY_EXCLUSIVE,
  [PRIMARY_PHRASE_FONT_SIZE]: MUTUALLY_EXCLUSIVE,
  [PRIMARY_PHRASE_POSITION]: MUTUALLY_EXCLUSIVE,
  // [SECONDARY_PHRASE_POSITION]: MUTUALLY_EXCLUSIVE,
  [WORD_COUNT]: MUTUALLY_EXCLUSIVE,
  [PEOPLE_VS_NO_PEOPLE]: MUTUALLY_EXCLUSIVE,
  // [NUMBER_OF_PEOPLE]: MUTUALLY_EXCLUSIVE,
  // [PEOPLE_TRAITS]: MULTIPLE_LABELS,
}
