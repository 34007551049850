import React, { useEffect, useState } from 'react';
import { useCreativeAnalyticsGet, useCreativeAnalyticsSet } from '../contexts';
import RulesModal from './Modal';
import {
  ADD_RULE,
  REMOVE_RULE,
  SET_RULE_INDEX,
  SET_RULES,
  useRulesManagerState,
} from './hooks/useRulesManagerState';
import { useCategories } from '../hooks';
import { useCreativeAnalyticsApis } from '../api';
import { useViewId } from '../selectors';

const Wrapper = () => {
  const { setShowRulesManager } = useCreativeAnalyticsSet();
  const {
    getRecoRulesData,
    getRecoRulesError,
    getRecoRulesLoading,
    getRecoRulesRequest,
  } = useCreativeAnalyticsGet();
  const rulesManagerState = useRulesManagerState();
  const {
    rulesManagerState: { rules, activeRuleIndex },
    rulesManagerDispatch,
  } = rulesManagerState;
  const { navCategories } = useCategories();
  const { recoRulesApi } = useCreativeAnalyticsApis();
  const {
    data: deleteRecoRulesData,
    error: deleteRecoRulesError,
    loading: deleteRecoRulesLoading,
    request: deleteRecoRulesRequest,
  } = recoRulesApi.delete;
  const {
    data: saveRecoRulesData,
    error: saveRecoRulesError,
    loading: saveRecoRulesLoading,
    request: saveRecoRulesRequest,
  } = recoRulesApi.post;
  const view_id = useViewId();
  const [showError, setShowError] = useState(false);
  const [showSaveSuccess, setShowSaveSuccess] = useState(false);
  const [showDeleteSuccess, setShowDeleteSuccess] = useState(false);

  useEffect(() => {
    if (deleteRecoRulesError || saveRecoRulesError) {
      setShowError(true);

      const timer = setTimeout(() => setShowError(false), 3000);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [deleteRecoRulesError, saveRecoRulesError]);

  useEffect(() => {
    if (saveRecoRulesData) {
      setShowSaveSuccess(true);
      fetchRecommendationRules();

      const timer = setTimeout(() => setShowSaveSuccess(false), 3000);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [saveRecoRulesData]);

  useEffect(() => {
    if (deleteRecoRulesData) {
      setShowDeleteSuccess(true);
      fetchRecommendationRules();

      const timer = setTimeout(() => setShowDeleteSuccess(false), 3000);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [deleteRecoRulesData]);

  const fetchRecommendationRules = () => {
    getRecoRulesRequest(view_id);
  };

  useEffect(() => {
    fetchRecommendationRules();
  }, []);

  useEffect(() => {
    if (getRecoRulesData) {
      const reversed = getRecoRulesData.toReversed();

      rulesManagerDispatch({
        type: SET_RULES,
        rules: reversed,
      });
    } else {
      rulesManagerDispatch({
        type: SET_RULES,
        rules: [],
      });
    }
  }, [getRecoRulesData]);

  useEffect(() => {
    if (deleteRecoRulesData) {
      fetchRecommendationRules();
    }
  }, [deleteRecoRulesData]);

  const handleInput = (type, value, ruleIndex) => {
    rulesManagerDispatch({
      type,
      value,
      ruleIndex,
    });
  };

  const handleRuleIndex = (i) => {
    if (i === activeRuleIndex) return;

    rulesManagerDispatch({
      type: SET_RULE_INDEX,
      ruleIndex: i,
    });
  };

  const handleAddRule = () => {
    rulesManagerDispatch({
      type: ADD_RULE,
    });
  };

  const handleDeleteRule = (rule_id, ruleIndex) => {
    if (!rule_id) {
      rulesManagerDispatch({
        type: REMOVE_RULE,
        ruleIndex,
      });

      setShowDeleteSuccess(true);

      const timer = setTimeout(() => setShowDeleteSuccess(false), 3000);
      return () => {
        clearTimeout(timer);
      };
    } else {
      deleteRecoRulesRequest({
        view_id,
        rule_ids: [rule_id],
      });
    }
  };

  const handleSaveRules = () => {
    saveRecoRulesRequest({
      view_id,
      rules: rules
        .filter((r) => r.category_a || r.label_a || r.category_b || r.label_b)
        .map((rule) => {
          if (!rule.rule_id) {
            const { [`rule_id`]: _, ...rest } = rule;
            return { ...rest };
          } else {
            return rule;
          }
        }),
    });
  };

  return (
    <div>
      <RulesModal
        {...{
          rules,
          setShowRulesManager,
          handleInput,
          handleRuleIndex,
          navCategories,
          handleAddRule,
          handleDeleteRule,
          handleSaveRules,
          saveRecoRulesLoading,
          showError,
          showSaveSuccess,
          showDeleteSuccess,
          getRecoRulesLoading,
          getRecoRulesError,
          deleteRecoRulesLoading,
        }}
      />
    </div>
  );
};

export default Wrapper;
