import React, { useState, useEffect, useMemo, useCallback, useReducer } from 'react';

import {
  MULTIPLE_LABELS,
  MUTUALLY_EXCLUSIVE,
  INVALID_CHARACTERS,
} from '../../constants';

const initialState = {
  name: '',
  description: '',
  type: 'easy_ai',
  labels: [],
  initialLabels: [],
};

const inputReducer = (state, action) => {
  switch (action.type) {
    case 'SET_FIELD':
      return { ...state, [action.field]: action.value };
    case 'SET_FIELDS':
      return { ...state, ...action.payload };
    case 'ADD_LABEL':
      return state.labels.includes(action.value)
        ? state
        : { ...state, labels: [...state.labels, action.value] };
    case 'REMOVE_LABEL':
      return {
        ...state,
        labels: state.labels.filter((label) => label !== action.value),
      };
    case 'ADD_INITIAL_LABEL':
      return {
        ...state,
        initialLabels: [...state.initialLabels, action.value],
      };
    default:
      return state;
  }
};

export const useInputState = () => {
  const [state, dispatch] = useReducer(inputReducer, initialState);

  const labelsHaveChanged = useMemo(
    () => !_.isEqual(state.initialLabels, state.labels),
    [state.initialLabels, state.labels]
  );

  const handleInput = useCallback((field, value) => {
    if (field === 'initial_labels') {
      dispatch({ type: 'ADD_INITIAL_LABEL', value });
    } else if (field === 'labels') {
      dispatch({ type: 'ADD_LABEL', value });
    } else {
      dispatch({ type: 'SET_FIELD', field, value });
    }
  }, []);

  const setFields = useCallback((fields) => {
    dispatch({ type: 'SET_FIELDS', payload: fields });
  }, []);

  const removeLabel = useCallback((label) => {
    dispatch({ type: 'REMOVE_LABEL', value: label });
  }, []);

  return [state, handleInput, removeLabel, labelsHaveChanged, setFields];
};

export const useSetupValidation = (
  input,
  existing,
  existingNames,
  labelInput
) => {
  let errors = [];
  let categoryInputErrors = [];
  let labelInputErrors = [];

  // validate label names
  if (labelInput.length) {
    if (existingNames.labels.includes(labelInput.toLowerCase())) {
      labelInputErrors.push(`${labelInput} is already in use, please choose a unique label
      name.`);
    }

    if (INVALID_CHARACTERS.some((c) => labelInput.includes(c))) {
      labelInputErrors.push(
        `Label names only allow the following special characters: ! ' - . * ( )`
      );
    }
  }

  // check if # of labels is valid
  if (input.name.length) {
    if (input.type === MUTUALLY_EXCLUSIVE) {
      if (input.labels.length < 2) {
        errors.push('Add two or more labels to continue.');
      }
    } else if (input.type === MULTIPLE_LABELS) {
      if (input.labels.length < 1) {
        errors.push('Add at least one label to continue.');
      } else if (input.labels.length > 4) {
        errors.push(
          'A maximum of four labels may be used for multiple labels categories.'
        );
      }
    }

    // check if category name is unique
    if (!existing || existing.category !== input.name) {
      if (existingNames.categories.includes(input.name.toLowerCase())) {
        errors.push(`${input.name} is already in use, please choose a unique category
        name.`);
        categoryInputErrors.push(`${input.name} is already in use, please choose a unique category
        name.`);
      }
    }

    // validate category name
    if (INVALID_CHARACTERS.some((c) => input.name.includes(c))) {
      errors.push(
        `Category names only allow the following special characters: ! ' - . * ( )`
      );
      categoryInputErrors.push(
        `Category names only allow the following special characters: ! ' - . * ( )`
      );
    }
  } else {
    errors.push('Please enter a name for the category.');
  }

  if (input.type.length < 1) {
    errors.push('Please choose a category type.');
  }

  return {
    errors,
    labelInputErrors,
    categoryInputErrors,
  };
};
