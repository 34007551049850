import React, { useEffect, useMemo, useRef } from 'react';

import {
  useTable,
  useSortBy,
  useFilters,
  useFlexLayout,
  useRowSelect,
} from 'react-table';
import LazyLoader from '../../components/LazyLoader';
import { STANDARD_CATEGORIES_LOOKUP } from '../CreativeAnalytics/categories';
import { useCategories } from '../CreativeAnalytics/hooks';
import { Cell } from './Cell';
import { useWindowSize } from '../../hooks/useWindowSize';
import { useCreativeAnalyticsGet } from '../CreativeAnalytics/contexts';
import { useScrollIntoView } from '../../hooks/useScrollIntoView';
import { getHiddenColumns } from '../CreativeAnalytics/PerformanceFrequency/utils';
import useIsVisibleAtCenter from '../../hooks/useVisibleAtCenter';
import { ErrorBoundary } from '../../components/Helpers/Error';
import { getCategoryOverview } from '../CreativeAnalytics/Explorer/utils/getCategoryOverview';
import { useFilteredColumns } from './hooks';
import { BreadcrumbItem, Breadcrumbs } from '../../components/Breadcrumbs';
import Skeleton from 'react-loading-skeleton';
import { useCategoriesLookup } from '../CreativeAnalytics/selectors';
import { useStaticReportContext } from './contexts';

const derivePfrProps = (data) => {
  const { labels, visuals, columns, selectedLabels } = data;

  return {
    labels: labels || [],
    visuals: visuals || [],
    columns: columns || [],
    selectedLabels: selectedLabels || [],
  };
};

export const StaticPfr = ({ data, formatMetric }) => {
  const { labels, visuals, columns, selectedLabels } = derivePfrProps(data);

  return (
    <>
      <div className="d-flex">
        <div className="col performance-frequency clearfix">
          {labels?.length > 0 ? <TablesWrapper columns={columns} /> : null}
        </div>
      </div>
    </>
  );
};

export const Row = ({ isSelected, row, isCompare, multiplier }) => {
  return (
    <>
      <tr
        {...row.getRowProps()}
        className={`frequency-table__row ${
          isSelected ? 'frequency-table__row--selected' : ''
        }`}
      >
        {row.cells.map((cell, i) => (
          <Cell cell={cell} isCompare={isCompare} multiplier={multiplier} />
        ))}
      </tr>
    </>
  );
};

export const Table = ({
  category_name,
  categoryData,
  categoryLabels,
  columns = [],
  isCompare,
}) => {
  const {
    category: highlightedCategory,
    isDirectMail,
    clientMetrics,
    filteredData,
    isOrganicView,
    account_id,
    isEmailView,
    isROAS,
    ga_enabled: gaEnabled,
    custom_events: customEvents,
    additional_metrics,
    customIntegratedMetrics,
    customScoringData,
    isWisdomPanel,
    selectedTab,
  } = useCreativeAnalyticsGet();
  const size = useWindowSize();
  const _ = require('lodash');
  const cols = useFilteredColumns(isCompare, columns);

  const isSelected =
    category_name === highlightedCategory ||
    category_name === STANDARD_CATEGORIES_LOOKUP[highlightedCategory];
  const ref = useRef();

  const memoed = useMemo(
    () => ({ cols, rows: categoryLabels }),
    [
      filteredData,
      clientMetrics,
      customEvents,
      columns,
      highlightedCategory,
      categoryLabels,
    ]
  );

  const hiddenCols = useMemo(
    () =>
      getHiddenColumns(
        columns,
        isROAS,
        isDirectMail,
        clientMetrics,
        gaEnabled,
        customEvents,
        additional_metrics,
        customIntegratedMetrics,
        isOrganicView,
        account_id,
        isEmailView,
        customScoringData,
        isWisdomPanel,
        selectedTab
      ),
    [
      filteredData,
      columns,
      isROAS,
      isDirectMail,
      clientMetrics,
      gaEnabled,
      customEvents,
      additional_metrics,
      customIntegratedMetrics,
      isOrganicView,
      account_id,
      isEmailView,
      customScoringData,
      isWisdomPanel,
      selectedTab,
    ]
  );

  console.log({ memoed, filteredData });
  const {
    getTableProps,
    getTableBodyProps,
    setHiddenColumns,
    rows,
    prepareRow,
    headerGroups,
  } = useTable(
    {
      columns: memoed.cols,
      data: memoed.rows,
      initialState: {
        hiddenColumns: [hiddenCols],
      },
    },
    useFilters,
    useSortBy,
    useFlexLayout,
    useRowSelect
  );

  useEffect(() => {
    setHiddenColumns(hiddenCols);
  }, [hiddenCols]);

  return (
    <div ref={ref}>
      <table
        {...getTableProps()}
        className={`frequency-table ${isSelected ? 'is-selected' : ''}`}
      >
        <thead className="frequency-table__header">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => {
                return (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    title={column.Header}
                    className={
                      column.isSorted
                        ? column.isSortedDesc
                          ? 'sorted-desc'
                          : 'sorted-asc'
                        : ''
                    }
                  >
                    <div className="table-header__text">
                      {column.render('Header')}
                    </div>

                    <span>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? ' 🔽'
                          : ' 🔼'
                        : ''}
                    </span>
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        {categoryLabels.length ? (
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);

              const isSelected =
                row.original.category === highlightedCategory ||
                highlightedCategory ===
                  STANDARD_CATEGORIES_LOOKUP[row.original.category];

              if (isOrganicView && row.original.name === 'All New Visuals') {
                return null;
              }

              return (
                <Row
                  {...{
                    isSelected,
                    row,
                    isCompare,
                    filteredData,
                  }}
                />
              );
            })}
          </tbody>
        ) : (
          <div className="d-flex flex-column align-items-center justify-content-center mx-auto w-100 mt-5">
            <div className="no-data-face">
              <span></span>
              <span></span>
            </div>
            <h5 className="my-0 ml-3 mt-3">No data to display.</h5>
          </div>
        )}
      </table>
    </div>
  );
};

export const ReportHeader = ({ category_name, categoryData, desc }) => {
  return (
    <div className="report-header">
      <div className="mb-4">
        <h3 className="report__title">
          Performance by <span>{category_name}</span>
        </h3>
        {desc && <p className="report__desc">{desc}</p>}
      </div>
    </div>
  );
};

export const Report = ({
  category_name,
  categoryData,
  categoryLabels,
  columns,
  isCompare = false,
}) => {
  const desc = getCategoryOverview({ category_name });
  const { ccc_desc } = categoryData;

  if (categoryLabels?.length < 1) return null;

  return (
    <div className="report">
      <ErrorBoundary>
        <ReportHeader
          category_name={category_name}
          categoryData={categoryData}
          desc={desc || ccc_desc}
        />
        <Table
          category_name={category_name}
          categoryData={categoryData}
          categoryLabels={categoryLabels}
          isCompare={isCompare}
          columns={columns}
        />
      </ErrorBoundary>
    </div>
  );
};

export const TablesWrapper = ({ columns }) => {
  const { category: selectedCategory } = useCreativeAnalyticsGet();
  const { categoriesLookup, labelsLookup, visualsLookup } =
    useStaticReportContext();
  const { tableData } = useCategories(null, {
    categoriesLookup,
    labelsLookup,
    visualsLookup,
  });
  const selectedCategoryData = !!selectedCategory
    ? tableData.find(
        (c) =>
          (STANDARD_CATEGORIES_LOOKUP[c.category_name] || c.category_name) ===
          selectedCategory
      )
    : null;
  const dataToDisplay =
    !!selectedCategoryData && selectedCategory !== 'All Visuals'
      ? [selectedCategoryData]
      : tableData.filter(
          (category) => category.category_name !== 'Phrase Theme'
        );

  console.log({
    dataToDisplay,
    selectedCategory,
    selectedCategoryData,
    tableData,
    categoriesLookup,
  });

  if (!categoriesLookup) return null;

  if (!tableData.some(({ labels }) => labels.length > 0)) {
    return (
      <div className="d-flex flex-column align-items-center justify-content-center mx-auto w-100 mt-5">
        <div className="no-data-face">
          <span></span>
          <span></span>
        </div>
        <h5 className="my-0 ml-3 mt-3">
          We found no matching visuals with the filters provided.
        </h5>
      </div>
    );
  }

  return dataToDisplay.map(({ category_name, category_data, labels }) => {
    return (
      <LazyLoader placeholder={<div style={{ height: '40px' }} />}>
        <Report
          category_name={category_name}
          categoryData={category_data}
          categoryLabels={labels}
          {...{ columns }}
        />
      </LazyLoader>
    );
  });
};
