export const SET_LOADER = '[UI] SET_LOADER';

export const setLoader = (entity, isLoading) => ({
  type: SET_LOADER,
  isLoading,
  meta: { entity },
});

export const setLoaderState = (state, entity, isLoading) => {
  console.log({
    state,
    entity,
    isLoading,
  });
  return state.Loaders[entity] = isLoading;
};
