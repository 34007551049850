import React, { Suspense, useEffect, useMemo, useRef } from 'react';
import {
  Switch,
  Route,
  Redirect,
  BrowserRouter,
  useRouteMatch,
  useLocation,
} from 'react-router-dom';

import './styles.scss';
import { StaticDnaReport } from './DNA';
import { StaticPfr } from './PFR';
import { abortableFetch } from '../../utils';
import {
  bindFetchToRef,
  useApiState,
} from '../../components/Hooks/useApiState';
import { ErrorBoundary } from '../../components/Helpers/Error';
import { CreativeAnalyticsProvider } from '../CreativeAnalytics/contexts';
import { BasicLoading } from '../../components/Helpers/Loading';
import { getCategoriesLookup } from '../CreativeAnalytics/utils';

import './styles.scss';
import '../CreativeAnalytics/PerformanceFrequency/PerformanceFrequency.scss';
import '../CreativeAnalytics/PerformanceFrequency/FrequencyTable.scss';
import '../CreativeAnalytics/PerformanceFrequency/Table/index.scss';
import Button from '../../components/Button';
import { useHistory } from 'react-router-dom';
import LeftDisplay from '../CreativeAnalytics/LeftDisplay';
import '../CreativeAnalytics/CreativeAnalytics.scss';
import '../CreativeAnalytics/Header/styles.scss';
import '../DNA/index.scss';
import '../DNA/Table.scss';
import { BreadcrumbItem, Breadcrumbs } from '../../components/Breadcrumbs';
import Skeleton from 'react-loading-skeleton';
import { useSearchParams } from '../../components/Hooks/search-params';
import CategoriesNav from './CategoriesNav';
import { CreativeRequestProvider } from '../CreativeAnalytics/CreativeRequest/contexts';
import { batch, useDispatch } from 'react-redux';
import {
  setCategoriesLookup,
  setHiddenLabels,
  setLabels,
  setVisuals,
} from '../../store/CreativeAnalytics/creativeAnalyticsSlice';
import { useWindowSize } from '../../hooks/useWindowSize';
import { StaticCopyReport } from './Copy';
import { updateVisualsLookup } from '../CreativeAnalytics/hooks/useFilteredData/utils/updateVisualsLookup';
import {
  normalizeLabels,
  normalizeVisuals,
} from '../CreativeAnalytics/utils/normalize';
import useTrackDeps from '../../hooks/useTrackDeps';
import StaticDraftCreative from './DraftCreative';
import { makeCategoriesLookup } from '../CreativeAnalytics/utils/makeCategoriesLookup';
import { CategoriesLookup } from '../CreativeAnalytics/ReportCard/types';
import StaticReportProvider from './contexts';

const get = (report_id) => {
  return abortableFetch(['api', 'get-static-report', report_id].join('/'), {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
    },
  });
};

export const useGetStaticReport = () => {
  const mounted = useRef(false);
  const withRef = bindFetchToRef(mounted);
  const [getState, getDispatch] = useApiState(
    (report_id) => withRef(get(report_id)).then(({ data }) => data),
    {
      data: null,
      error: null,
      loading: true,
      count: 0,
    }
  );

  useEffect(() => {
    mounted.current = true;
    return () => (mounted.current = false);
  }, []);

  return {
    ...getState,
    apiData: getState.data,
    request: getDispatch,
  };
};

const defaultData = {
  report_name: '',
  report_description: '',
  start_date: '',
  end_date: '',
  data: {
    report_type: 'dna',
  },
};

export const formatDate = (str) => {
  const dateObj = new Date(str);

  const formattedDate = dateObj.toLocaleDateString('en-US', {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
  });

  return formattedDate;
};

const StaticReport = ({
  report_name,
  report_description,
  start_date,
  end_date,
  data,
  report_type,
  metadataFields,
  metadata,
  visualsForSelectedLabel = [],
  isVisualsView,
  navigateBack,
  selectedLabels = [],
  activeLabel = null,
}) => {
  const metricsLookup = useMemo(() => {
    const resultMap = new Map();
    data?.columnOptions?.forEach((obj) => {
      if (obj?.value) {
        resultMap.set(obj.value, obj.label);
      }
    });
    return resultMap;
  }, [data]);

  const formatMetric = (metric): string => {
    const match = metricsLookup?.get(metric);

    if (match) {
      return match;
    }

    return metric;
  };

  console.log({ data });

  let BreadcrumbsHeader = (
    <div className="d-flex align-items-center justify-content-between">
      <Breadcrumbs>
        {selectedLabels.map((label, i) => {
          if (label.isLoading) {
            return (
              <BreadcrumbItem variant="custom" text="loading">
                <Skeleton
                  style={{
                    width: '40px',
                    borderRadius: '25px',
                  }}
                  count={1}
                />
              </BreadcrumbItem>
            );
          }

          return (
            <BreadcrumbItem
              variant="text"
              text={label.label}
              // onClick={() => handleFilter(i, label)}
            />
          );
        })}
      </Breadcrumbs>
    </div>
  );

  let Comp = null;

  switch (report_type) {
    case 'pfr':
      Comp = (
        <StaticPfr
          {...{
            report_name,
            report_description,
            start_date,
            end_date,
            data,
            formatMetric,
          }}
        />
      );
      break;
    case 'copy':
      Comp = (
        <StaticCopyReport
          {...{
            report_name,
            report_description,
            start_date,
            end_date,
            data,
          }}
        />
      );
      break;
    case 'report_card':
      Comp = (
        <StaticReportCard
          {...{
            report_name,
            report_description,
            start_date,
            end_date,
            data,
          }}
        />
      );
      break;
    case 'draft-creative':
      Comp = (
        <StaticDraftCreative {...{ report_name, report_description, data }} />
      );
      break;
    case 'dna':
    default:
      Comp = (
        <StaticDnaReport
          {...{
            report_name,
            report_description,
            start_date,
            end_date,
            data,
            formatMetric,
            metadataFields,
            visualsForSelectedLabel,
            activeLabel,
            metadata,
            metricsLookup
          }}
        />
      );
  }

  return (
    <>
      {!!selectedLabels?.length && (
        <div
          className={`analytics__header`}
          style={{ borderBottom: '1px solid #e4e9f0' }}
        >
          {BreadcrumbsHeader}
        </div>
      )}

      <div className="static-report">
        <div className="p-5">
          <h1>{report_name?.length ? report_name : 'Untitled report'}</h1>
          {report_type !== 'draft-creative' && (
            <h2 className="static-report__date-range">
              {formatDate(start_date)} - {formatDate(end_date)}
            </h2>
          )}
          {!!report_description?.length && (
            <p className="static-report__description">{report_description}</p>
          )}
        </div>
        <hr className="m-0" />
        {isVisualsView && (
          <div className="mt-3 ml-3 d-flex align-items-center">
            <Button className="" onClick={navigateBack} appearance="raised">
              {'<'} Back
            </Button>
            <p className="m-0 ml-3">{activeLabel}</p>
          </div>
        )}
        {Comp}
      </div>
    </>
  );
};

export const restoreData = (data) => {
  if (typeof data === 'string') {
    return data.replace(/___/g, '.');
  } else if (Array.isArray(data)) {
    return data.map(restoreData);
  } else if (typeof data === 'object' && data !== null) {
    const result = {};
    for (const [key, value] of Object.entries(data)) {
      const newKey = key.replace(/___/g, '.');

      result[newKey] = restoreData(value);
    }
    return result;
  } else {
    return data;
  }
};
const deriveProps = (data) => {
  if (!data) return {};

  const {
    labels,
    visuals,
    columns,
    reportCardSettings,
    selectedLabels,
    primary_metric,
    metadataFields,
    metadata,
    viewData,
    columnOptions,
    dnaColumnOptions,
    report_type,
    hiddenLabels,
    filteredAllVisualsLabel,
    customScoringData,
  } = restoreData(data);

  return {
    filteredData: labels || [],
    visuals: visuals || [],
    columns: columns || [],
    reportCardSettings,
    selectedLabels: selectedLabels || [],
    primary_metric: primary_metric || (viewData?.isROAS ? 'roas' : 'cpa'),
    customIntegratedMetrics: viewData?.customIntegratedMetrics || [],
    custom_events: viewData?.custom_events || [],
    additional_metrics: viewData?.additional_metrics || [],
    isOrganicView: viewData?.isOrganicView || false,
    isDirectMail: viewData?.isDirectMail || false,
    isEmailView: viewData?.isEmailView || false,
    ga_enabled: viewData?.ga_enabled || false,
    isROAS: viewData?.isROAS || false,
    columnOptions: columnOptions || [],
    dnaColumnOptions: dnaColumnOptions || [],
    metadataFields: metadataFields || [],
    metadata: metadata || {},
    report_type: report_type || 'dna',
    hiddenLabels: hiddenLabels || [],
    filteredAllVisualsLabel,
    customScoringData,
  };
};

const Wrapper = ({ rid = null }) => {
  const [, , reportIdFromUrl] = window.location.pathname.split('/');
  const { apiData, loading, error, request } = useGetStaticReport();
  const history = useHistory();
  const location = useLocation();
  const { getParams } = useSearchParams();
  const { category } = getParams('category');
  const dispatch = useDispatch();
  const size = useWindowSize();
  const isMobile = size?.width < 768;
  const report_id = rid || reportIdFromUrl;

  useEffect(() => {
    if (report_id) {
      request(report_id);
    }
  }, [report_id]);

  const {
    reportCardSettings,
    selectedLabels,
    customIntegratedMetrics,
    custom_events,
    additional_metrics,
    isOrganicView,
    isDirectMail,
    isEmailView,
    ga_enabled,
    isROAS,
    columnOptions,
    primary_metric,
    filteredData,
    visuals,
    metadataFields,
    dnaColumnOptions,
    report_type,
    hiddenLabels,
    metadata,
    filteredAllVisualsLabel,
    customScoringData,
  } = useMemo(() => deriveProps(apiData?.data), [apiData]);

  useEffect(() => {
    if (hiddenLabels?.length) {
      dispatch(setHiddenLabels(hiddenLabels));
    }
  }, [hiddenLabels?.length]);


  const categoriesLookup: CategoriesLookup = useMemo(
    () =>
      makeCategoriesLookup({
        labels: filteredData,
        isROAS,
        isDirectMail,
        reportCardDataType: '',
        viewName: '',
        reportCardMetric: '',
        spendThreshold: 0,
      }),
    [filteredData, isROAS, isDirectMail]
  );

  const visualsLookup = useMemo(
    () => normalizeVisuals(visuals || []),
    [visuals]
  );

  const labelsLookup = useMemo(() => {
    if (filteredData?.length) {
      return normalizeLabels(filteredData);
    }

    return [];
  }, [filteredData]);

  // useEffect(() => {
  //   batch(() => {
  //     if (visualsLookup) {
  //       dispatch(
  //         setVisuals(
  //           updateVisualsLookup(visualsLookup, categoriesLookup, filteredData)
  //         )
  //       );
  //     }
  //     if (filteredData?.length) {
  //       dispatch(setLabels(normalizeLabels(filteredData)));
  //     }

  //     dispatch(setCategoriesLookup(categoriesLookup));
  //   });
  // }, [filteredData, visualsLookup, categoriesLookup]);

  if (!apiData) return null;

  const match = useRouteMatch();

  const navigateBack = () => {
    history.goBack();
  };
  const isVisualsPath = location.pathname.includes('visuals');
  const isPFR = report_type === 'pfr' && !isVisualsPath;
  const allVisualsLabel =
    filteredAllVisualsLabel ??
    filteredData.find((label) => label.value === 'all_visuals');

  // set labels and visuals in redux

  return (
    <ErrorBoundary>
      <StaticReportProvider
        labelsLookup={labelsLookup?.lookup}
        visualsLookup={visualsLookup?.lookup}
        categoriesLookup={categoriesLookup}
      >
        <CreativeAnalyticsProvider
          filteredData={filteredData}
          isROAS={isROAS}
          primary_metric={primary_metric}
          isStaticReport={true}
          reportCardSettings={reportCardSettings}
          selectedLabels={selectedLabels}
          customIntegratedMetrics={customIntegratedMetrics}
          customEvents={custom_events}
          additional_metrics={additional_metrics}
          isOrganicView={isOrganicView}
          isDirectMail={isDirectMail}
          isEmailView={isEmailView}
          ga_enabled={ga_enabled}
          category={category}
          filteredAllVisualsLabel={allVisualsLabel}
          columnDropdownOptions={
            report_type === 'dna' ? dnaColumnOptions : columnOptions
          }
          categoryFilters={{
            showAdditionalCategories: false,
            showComplianceCategories: false,
            showOnlyComplianceCategories: false,
            showTestingOpportunities: false,
            includeUdc: true,
          }}
          requests={[]}
          customScoringData={customScoringData}
        >
          <CreativeRequestProvider>
            <div className="analytics">
              {isPFR && !isMobile && <CategoriesNav />}

              <div className="analytics__content">
                <Switch>
                  <Route path={`${match.path}/visuals/:label`} exact>
                    {({ match }) => {
                      const visualsForSelectedLabel =
                        getVisualsForSelectedLabel(
                          filteredData,
                          visuals,
                          match.params.label
                        );

                      return (
                        <Suspense fallback={<BasicLoading />}>
                          <StaticReport
                            {...apiData}
                            report_type="dna"
                            visualsForSelectedLabel={visualsForSelectedLabel}
                            isVisualsView={true}
                            navigateBack={navigateBack}
                            selectedLabels={selectedLabels}
                            activeLabel={match.params.label}
                          />
                        </Suspense>
                      );
                    }}
                  </Route>

                  <Route path={`${match.path}`} exact>
                    {({ match }) => {
                      return (
                        <Suspense fallback={<BasicLoading />}>
                          {
                            <StaticReport
                              {...apiData}
                              metadataFields={metadataFields}
                              metadata={metadata}
                              report_type={report_type ?? 'dna'}
                              selectedLabels={selectedLabels}
                            />
                          }
                        </Suspense>
                      );
                    }}
                  </Route>
                </Switch>
              </div>
            </div>
          </CreativeRequestProvider>
        </CreativeAnalyticsProvider>
      </StaticReportProvider>
    </ErrorBoundary>
  );
};

export default Wrapper;

const getVisualsForSelectedLabel = (labels, visuals, label) => {
  const match = labels.find((lbl) => lbl.value === label);

  return (
    match?.visuals.map((id) => visuals.find((visual) => visual.id === id)) || []
  );
};
