import React, { useLayoutEffect, useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { useSearchParams } from '../Hooks/search-params';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { GlobalApiWrapper } from '../GlobalApi/GlobalApi';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import { LOGIN, LOGIN_FETCH } from '../../store/background/api.login';
import {
  VIEW_SETTINGS,
  VIEW_AUDIENCE,
  setViewSettings,
} from '../../store/ViewSettings/actions';

import Nav from '../Nav';
import ToastWrapper from '../StatusAlerts/Wrapper';
import Portal from '../Overlay/Portal';
import { toast, ToastContainer } from 'react-toastify';
import { Routes } from './Routes';
import { getViewId } from '../../store/utils';
import AnalyzeUrl from '../Analytics';

import { useViewId } from '../Hooks/view-id';
import { BasicError } from '../Helpers/Error';
import { loadZendesk } from '../ScriptInjections';

import { isContractorRole } from '../../cookies';

import './styles.scss';
import 'react-toastify/dist/ReactToastify.css';
import { ViewGroup } from '../../features/ViewGroup';
import { useViewSettings } from '../../selectors';
import { ViewGroupProvider } from '../../features/ViewGroup/context';
import { BasicLoading } from '../Helpers/Loading';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false, // default: true
    },
  },
});

function App() {
  const viewId = getViewId();
  beginTokenFlow(viewId);

  const [mainView, setMainView] = useState(null);

  return (
    <Router>
      <GlobalApiWrapper>
        <ViewGroupProvider {...{ mainView, setMainView }}>
          <QueryClientProvider client={queryClient} contextSharing={true}>
            <AnalyzeUrl />
            <ToastWrapper />
            <Portal>
              <ToastContainer closeButton={false} />
            </Portal>
            <Nav {...{ mainView }} />
            <RouteWrapper {...{ mainView, setMainView }} />
            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
        </ViewGroupProvider>
      </GlobalApiWrapper>
    </Router>
  );
}

export default App;

export const beginTokenFlow = (viewId, isStaticReport = false) => {
  const dispatch = useDispatch();
  const fetchToken = () =>
    dispatch({
      type: LOGIN_FETCH,
      data: { email: null, password: null },
      meta: {
        viewId,
        entity: LOGIN,
        getLocalStorage: {
          key: 'token',
        },
      },
    });

  useLayoutEffect(() => {
    let fetched = false;

    if (!fetched) {
      fetchToken();
    }

    return () => {
      fetched = true;
    };
  }, []);
};

const IGNORE_VIEW_GROUP = [
  'marketplace',
  'clients',
  'report-groups',
  'view-settings',
  'manage-labels',
  'budget-boost',
  'lever-admin',
  'lever-setup',
  'block-manager',
  'refresh',
];

const shouldIgnore = (viewId) => {
  if (!viewId?.length) return true;

  // if (!window.location.pathname.includes('analytics')) return true;

  return IGNORE_VIEW_GROUP.some((rte) =>
    window.location.pathname.includes(rte)
  );
};

const RouteWrapper = ({ mainView, setMainView }) => {
  const [shouldShowViewGroup, setShouldShowViewGroup] = useState(true);

  const viewId = useViewId();
  const viewSettings = useViewSettings();
  const {
    error,
    loading,
    loggedIn,
    creative_studio_only,
    isNonPaidMediaType,
    isUltraLight,
    report_group_id,
  } = useViewStatus();

  useEffect(() => {
    if (loggedIn) {
      loadZendesk();
    }
  }, [loggedIn]);

  useEffect(() => {
    if (report_group_id) {
      if (!mainView) {
        setMainView(viewSettings);
      }
    }
  }, [mainView, report_group_id, viewSettings]);

  const rgid = report_group_id || mainView?.report_group_id;

  if (
    rgid &&
    shouldShowViewGroup &&
    !shouldIgnore(viewId) &&
    window.location.pathname.includes('analytics')
  ) {
    return (
      <>
        <ToastContainer />
        <ViewGroup {...{ error }} report_group_id={rgid} />
      </>
    );
  }

  if (error) {
    return (
      <div style={{ marginTop: '60px' }}>
        <BasicError />
      </div>
    );
  }

  return (
    <div className="d-flex" id="wrapper">
      <div
        id="page-content-wrapper"
        style={{
          background: !viewId ? '#fff' : 'inherit',
        }}
      >
        <Routes
          loggedIn={loggedIn}
          viewId={viewId}
          loading={loading}
          creative_studio_only={creative_studio_only}
          isNonPaidMediaType={isNonPaidMediaType}
          isUltraLight={isUltraLight}
        />
      </div>
    </div>
  );
};

export const useViewStatus = () => {
  const {
    loadingView,
    isLoading,
    hasError,
    loggedIn,
    creative_studio_only,
    isNonPaidMediaType,
    isUltraLight,
    report_group_id,
  } = useSelector(
    ({
      ViewSettings: {
        account_id,
        loggedIn,
        creative_studio_only,
        isNonPaidMediaType,
        isUltraLight,
        report_group_id,
      },
      Loaders,
      Errors,
    }) => ({
      loadingView: isContractorRole() ? false : loggedIn && account_id === '',
      hasError: !!(Errors[VIEW_SETTINGS] || Errors[VIEW_AUDIENCE]),
      isLoading: !!(Loaders[VIEW_SETTINGS] || Loaders[VIEW_AUDIENCE]),
      loggedIn,
      creative_studio_only,
      isNonPaidMediaType,
      isUltraLight,
      report_group_id,
    })
  );

  return {
    error: hasError,
    loading: isLoading || loadingView,
    loggedIn,
    creative_studio_only,
    isNonPaidMediaType,
    isUltraLight,
    report_group_id,
  };
};
