import React, { useEffect, useRef, useState } from 'react';
import './Copilot.scss';
import '../../../../CreativeCopilot/Output/Output.scss';
import { useSuggestionsContext } from '../../contexts';
import { CopilotIcon } from '../../../../CreativeCopilot/components/CopilotIcon';
import LoadingMessages from '../../../../../../components/Helpers/LoadingMessages';
import { LOADING_MESSAGES } from '../../../../CreativeCopilot/constants';
import Button from '../../../../../../components/Button';
import Tooltip from '../../../../../../components/Tooltip';
import { useCreativeRequestSet } from '../../../../CreativeRequest/contexts';
import { useReportCardContext } from '../../../contexts';
import CollapsibleItem from '../../../../../../components/CollapsibleItem/CollapsibleItem';
import { SettingsIcon } from '../../../Header/icons';
import SettingsModal from '../components/SettingsModal';
import { useViewId } from '../../../../selectors';
import { useVariationsContext } from '../../../SuggestSimilarVisuals/SuggestSimilarVisuals';

const Copilot = ({
  showCopilotSettings,
  setShowCopilotSettings,
  updatedPreferences,
  setUpdatedPreferences,
  updatedDetails,
  setUpdatedDetails,
  handleSaveSettings,
  saveSettingsData,
  saveSettingsError,
  saveSettingsLoading,
}) => {
  const {
    copilotData: data,
    copilotLoading: loading,
    copilotRequestError,
    fetchCopilot,
    userFeedback,
    setUserFeedback,
    previousOutput,
  } = useSuggestionsContext();

  const { addToOverview } = useCreativeRequestSet();
  const { current, advertiserDetails, clientPreferences } =
    useReportCardContext();
  const [indexAdded, setIndexAdded] = useState(null);
  const [justAdded, setJustAdded] = useState(false);
  const [displayHeight, setDisplayHeight] = useState(0);
  const ref = useRef(null);
  const { visuals, selectedIndex, prev, next, error, request } =
    useVariationsContext();
  const selectedVariation = visuals?.selectedIndex;

  useEffect(() => {
    setDisplayHeight(ref.current?.clientHeight);
  });

  const expandDown = displayHeight < 200;

  const handleOverview = (value, i) => {
    setIndexAdded(i);
    setJustAdded(true);
    addToOverview(value, current.asset_url, current.visual_type, '', '', true);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setJustAdded(false);
      setIndexAdded(null);
    }, 1500);
    return () => {
      clearTimeout(timer);
    };
  }, [indexAdded]);

  let Display = <div></div>;

  if (copilotRequestError) {
    Display = <div>There was an error.</div>;
  }

  if (loading) {
    Display = (
      <div className="d-flex align-items-center justify-content-center flex-column">
        <div className={`icon icon--placeholder spin-loading`}>
          <CopilotIcon />
        </div>
        <LoadingMessages messages={LOADING_MESSAGES} delay={5000} />
      </div>
    );
  }

  if (data && !loading) {
    const recommendations = Array.from(
      data.recommendation_summary.matchAll(/(?<=<li>)(.*?)(?=<\/li>)/g),
      (x) => x[1]
    );

    Display = (
      <div className="summary-text">
        <div className="mb-4" style={{ fontWeight: 600 }}>
          Suggestions for improving the visual:
        </div>
        <ul>
          {recommendations.map((rec, i) => {
            return (
              <li className="mb-4">
                <Tooltip
                  content={
                    indexAdded === i && justAdded
                      ? 'Added!'
                      : 'Add to creative request overview'
                  }
                >
                  <div
                    className="add-button"
                    onClick={() => handleOverview(rec, i)}
                  >
                    <i
                      className={`fa-solid fa-${
                        indexAdded === i && justAdded ? 'check' : 'plus'
                      }`}
                    />
                  </div>
                </Tooltip>
                {rec}
              </li>
            );
          })}
        </ul>
        <div style={{ fontWeight: 600 }}>
          Use your best judgment and be creative! Recommendations for video
          assets are only for the first 3 seconds of the video.
        </div>
      </div>
    );
  }

  return (
    <div className="suggestions__copilot">
      <div ref={ref}>{Display}</div>
      {previousOutput && !loading && (
        <div className="feedback">
          <CollapsibleItem
            AnchorChild={
              <div>
                Feedback? <i className="fa-regular fa-comment ml-1" />
              </div>
            }
            ExpandableChild={
              <div>
                {' '}
                <textarea
                  className="text-input"
                  onChange={(e) => setUserFeedback(e.target.value)}
                  value={userFeedback}
                />
                <p className="feedback__note">
                  This feedback is only applied to the current recommendation
                  and will not be stored.
                </p>
                <Button
                  onClick={() =>
                    fetchCopilot(updatedPreferences, updatedDetails)
                  }
                  loading={loading}
                  disabled={loading}
                >
                  Submit
                </Button>
              </div>
            }
            expandOnLoad={false}
          />
          <div className="copilot-settings">
            <div
              onClick={() => setShowCopilotSettings((prev) => !prev)}
              className="d-flex justify-content-end"
              style={{ zIndex: 1, position: 'relative' }}
            >
              <SettingsIcon />
            </div>
            <SettingsModal
              {...{
                showCopilotSettings,
                setShowCopilotSettings,
                updatedPreferences,
                setUpdatedPreferences,
                updatedDetails,
                setUpdatedDetails,
                handleSaveSettings,
                saveSettingsData,
                saveSettingsError,
                saveSettingsLoading,
                clientPreferences,
                advertiserDetails,
                expandDown,
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Copilot;
