import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import React, { useState } from 'react';
import { AlertModal } from '../components/AlertModal';
const useConfirm = (title, message) => {
  const [promise, setPromise] = useState(null);

  const confirm = () =>
    new Promise((resolve, reject) => {
      setPromise({ resolve });
    });

  const handleClose = () => {
    setPromise(null);
  };

  const handleConfirm = () => {
    promise?.resolve(true);
    handleClose();
  };

  const handleCancel = () => {
    promise?.resolve(false);
    handleClose();
  };
  
  const ConfirmationDialog = () => (
    <AlertModal
      title={title}
      message={message}
      show={promise !== null}
      close={handleCancel}
      onConfirm={handleConfirm}
    ></AlertModal>
  );

  return [ConfirmationDialog, confirm];
};

export default useConfirm;
