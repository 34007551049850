export const ANALYTICS = 'analytics';
export const ROADMAP = 'roadmap';
export const STUDIO = 'studio';
export const CREATIVE_REPORTS = 'creative_reports';

export const DASHBOARD = 'dashboard';
export const TRENDING = 'trending';
export const LEGACY_REPORTS = 'legacy_reports';
export const LIBRARY = 'library';
export const BLOCK_REPORT = 'block_report';
export const ADSET_REPORT = 'adset_report';
export const AD_REPORT = 'ad_report';
export const BETA_REPORTS = 'beta_reports';
export const PHRASE_THEME_REPORT = 'phrase_theme_report';
export const IMPACT_REPORT = 'impact_report';
export const COPILOT = 'copilot';
export const COPY_REPORT = 'copy_report';
export const PRESETS = 'presets';
export const ANNOTATE_VISUALS = 'annotate_visuals';
export const SETTINGS = 'settings';
export const GROUP_BY_AD_NAME = 'group_by_ad_name';
export const REPORT_CARD_TAB = 'report_card_tab';
export const DRAFT_CREATIVE = 'draft-creative';
export const AUTO_FETCH_SCORES = 'auto_fetch_scores';
export const GHOST_TABS = 'ghost-tabs';
export const COPY_SUMMARY = 'copy-summary';

export const DISPLAY_LOOKUP = {
  [ANALYTICS]: 'Analytics',
  [ROADMAP]: 'Roadmap',
  [STUDIO]: 'Studio',
  [CREATIVE_REPORTS]: 'Creative reports',
  [DASHBOARD]: 'Dashboard',
  [TRENDING]: 'Trending',
  [LEGACY_REPORTS]: 'Legacy reports',
  [LIBRARY]: 'Library',
  [BLOCK_REPORT]: 'Block report',
  [ADSET_REPORT]: 'Adset report',
  [AD_REPORT]: 'Ad report',
  [BETA_REPORTS]: 'Beta reports',
  [PHRASE_THEME_REPORT]: 'Phrase theme report',
  [IMPACT_REPORT]: 'Impact report',
  [COPILOT]: 'Creative copilot',
  [COPY_REPORT]: 'Copy report',
  [PRESETS]: 'Presets',
  [ANNOTATE_VISUALS]: 'Annotate visuals (client users only)',
  [SETTINGS]: 'Settings (client users only)',
  [GROUP_BY_AD_NAME]: 'Group by ad name is default',
  [REPORT_CARD_TAB]: 'Report card tab',
  [DRAFT_CREATIVE]: 'Draft creative',
  [AUTO_FETCH_SCORES]: 'Automatically fetch custom scoring',
  [GHOST_TABS]: 'Ghost tabs',
  [COPY_SUMMARY]: 'Copy summary',
};

export const SPECIFIC_FEATURES = [
  PRESETS,
  ANNOTATE_VISUALS,
  SETTINGS,
  GROUP_BY_AD_NAME,
  REPORT_CARD_TAB,
  DRAFT_CREATIVE,
  AUTO_FETCH_SCORES,
  GHOST_TABS,
  COPY_SUMMARY,
];

export const MODULES = [ANALYTICS, ROADMAP, STUDIO, CREATIVE_REPORTS];

export const SPECIFIC_REPORTS = [
  DASHBOARD,
  TRENDING,
  LEGACY_REPORTS,
  LIBRARY,
  BLOCK_REPORT,
  ADSET_REPORT,
  AD_REPORT,
  BETA_REPORTS,
  PHRASE_THEME_REPORT,
  IMPACT_REPORT,
  COPILOT,
  COPY_REPORT,
];

export const ALL_FEATURES = [
  ...MODULES,
  ...SPECIFIC_REPORTS,
  ...SPECIFIC_FEATURES,
];

export const MOCK_PERMISSIONS = {
  [ANALYTICS]: true,
  [ROADMAP]: true,
  [STUDIO]: true,
  [CREATIVE_REPORTS]: true,
  [DASHBOARD]: true,
  [TRENDING]: false,
  [LEGACY_REPORTS]: true,
  [LIBRARY]: false,
  [BLOCK_REPORT]: true,
  [ADSET_REPORT]: true,
  [AD_REPORT]: true,
  [BETA_REPORTS]: true,
  [PHRASE_THEME_REPORT]: true,
  [IMPACT_REPORT]: true,
};
