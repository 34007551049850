import React, { useEffect } from 'react';
import './Modal.scss';
import Overlay from '../../../components/Overlay';
import Modal from '../../../components/Modal';
import {
  SET_CATEGORY_A,
  SET_CATEGORY_B,
  SET_EXPLANATION,
  SET_LABEL_A,
  SET_LABEL_B,
} from './hooks/useRulesManagerState';
import Select from 'react-select';
import Button from '../../../components/Button';

const RulesModal = ({
  rules,
  setShowRulesManager,
  handleInput,
  handleRuleIndex,
  navCategories,
  handleAddRule,
  handleDeleteRule,
  handleSaveRules,
  saveRecoRulesLoading,
  showError,
  showSaveSuccess,
  showDeleteSuccess,
  getRecoRulesLoading,
  getRecoRulesError,
  deleteRecoRulesLoading,
}) => {
  const categoryOptions = navCategories
    .filter(
      ({ label }) => !['All Visuals', 'Top 10 Spending Visuals'].includes(label)
    )
    .map(({ label, value }) => ({
      label,
      value,
    }));

  let Display = null;

  if (getRecoRulesLoading) {
    Display = (
      <div
        className="d-flex align-items-center justify-content-center w-100"
        style={{ width: 100, height: '55vh' }}
      >
        <i
          className="fas fa-spinner fa-spin"
          style={{ fontSize: '3rem', opacity: 0.6 }}
        />
      </div>
    );
  } else if (rules.length) {
    Display = (
      <ul className="rules-list">
        {rules.map((rule, i) => {
          const { rule_id, category_a, label_a, category_b, label_b } = rule;

          const Explanation =
            category_a || label_a || category_b || label_b ? (
              <div>
                {`Don't recommend ${category_a ? category_a : ''}${
                  label_a ? `${category_a ? ' -> ' : ''}${label_a}` : ''
                }${category_b ? ` with ${category_b}` : ''}${
                  label_b ? `${category_b ? ' -> ' : ''}${label_b}` : ''
                }`}
              </div>
            ) : null;

          const labelAOptions = category_a
            ? navCategories
                .find((c) => c.value === category_a)
                ?.labels.map(({ name }) => ({ label: name, value: name }))
            : [];

          const labelBOptions = category_b
            ? navCategories
                .find((c) => c.value === category_b)
                ?.labels.map(({ name }) => ({ label: name, value: name }))
            : [];

          return (
            <li
              className="rules-list__row d-flex flex-column"
              onClick={() => handleRuleIndex(i)}
            >
              <div className="d-flex">
                <SelectCell
                  label="Category A"
                  handleInput={handleInput}
                  inputType={SET_CATEGORY_A}
                  options={categoryOptions}
                  value={
                    category_a ? { label: category_a, value: category_a } : null
                  }
                  disabled={false}
                  i={i}
                />
                <SelectCell
                  label="Label A"
                  handleInput={handleInput}
                  inputType={SET_LABEL_A}
                  options={labelAOptions}
                  value={label_a ? { label: label_a, value: label_a } : null}
                  disabled={!category_a}
                  i={i}
                />
                <SelectCell
                  label="Category B"
                  handleInput={handleInput}
                  inputType={SET_CATEGORY_B}
                  options={categoryOptions}
                  value={
                    category_b ? { label: category_b, value: category_b } : null
                  }
                  disabled={false}
                  i={i}
                />
                <SelectCell
                  label="Label B"
                  handleInput={handleInput}
                  inputType={SET_LABEL_B}
                  options={labelBOptions}
                  value={category_b ? { label: label_b, value: label_b } : null}
                  disabled={!category_b}
                  i={i}
                />
              </div>
              {(category_a || label_a || category_b || label_b) && (
                <div className="rules-list__cell d-flex flex-column w-100">
                  <label className="mt-3">Explanation</label>
                  {Explanation}
                </div>
              )}
              <div className="delete-btn">
                <div onClick={() => handleDeleteRule(rule_id, i)}>
                  {deleteRecoRulesLoading ? (
                    <i className="fas fa-spinner fa-spin" />
                  ) : (
                    <i className="fa-solid fa-trash" />
                  )}
                </div>
              </div>
            </li>
          );
        })}
      </ul>
    );
  } else {
    Display = <div>No rules yet.</div>;
  }

  return (
    <Overlay close={() => setShowRulesManager(false)} show>
      <Modal className="rules-manager__modal">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <Button appearance="raised" onClick={handleAddRule}>
            <i className="fa-solid fa-circle-plus mr-2" />
            Add rule
          </Button>
          <div className="d-flex align-items-center">
            <div className="mr-4">{`${
              showSaveSuccess
                ? 'Saved.'
                : showDeleteSuccess
                ? 'Deleted.'
                : showError
                ? 'There was an error.'
                : ''
            }`}</div>
            <Button
              onClick={handleSaveRules}
              loading={saveRecoRulesLoading}
              disabled={!rules.length}
            >
              Save
            </Button>
            <div
              className="close-btn ml-4"
              onClick={() => setShowRulesManager(false)}
              style={{ cursor: 'pointer' }}
            >
              <i className="fa fa-close" />
            </div>
          </div>
        </div>
        <div className="w-100">{Display}</div>
      </Modal>
    </Overlay>
  );
};

export default RulesModal;

const SelectCell = ({
  label,
  handleInput,
  inputType,
  options,
  value,
  disabled,
  i,
}) => {
  return (
    <div className="rules-list__cell d-flex flex-column">
      <div className="d-flex justify-content-between align-items-center pr-3">
        <label className="">{label}</label>
        <ClearButton onClick={() => handleInput(inputType, null, i)} />
      </div>
      <Select
        options={options}
        value={value}
        onChange={(e) => handleInput(inputType, e.value, i)}
        disabled={disabled}
      />
    </div>
  );
};

const ClearButton = ({ onClick }) => {
  return (
    <i
      className="fa fa-close clear-btn"
      style={{ marginBottom: '0.5rem', cursor: 'pointer', color: '#aaa' }}
      onClick={onClick}
    />
  );
};
