import { combineReducers } from 'redux';
import CreativeTesting from './CreativeTesting/reducers';
import CreativeReports from './CreativeReports/reducers';
import CreativeAnalytics from './CreativeAnalytics/creativeAnalyticsSlice.ts';
import ViewSettings from './ViewSettings/reducers';
import AdPreviews from './AdPreviews/reducers';
import RunningAdsByName from './RunningAdsByName/reducers';
import CreateAd from './CreateAd/reducers';
import { marketplaceReducer } from './Marketplace/index';
import Adsets from './Adsets/reducers';
import Errors from './Errors/reducers';
import Loaders from './Loaders/reducers';
import Notifications from './Notifications/reducer';
import { lookupReducer } from './Lookups';
import { RESETTING_APP } from './background/api.logout';
import INITIAL_STATE from './initial-state';
// import api from '../features/BulkActions/Audience/api';
// import { analyticsApi } from './CreativeAnalytics/api.ts';

export default (state, action) =>
  combineReducers({
    AdPreviews,
    ViewSettings,
    RunningAdsByName,
    Adsets,
    Errors,
    Loaders,
    Notifications,
    CreativeTesting,
    CreativeAnalytics,
    CreativeReports,
    CreateAd,
    Marketplace: marketplaceReducer,
    Lookups: lookupReducer,
    // [api.reducerPath]: api.reducer,
    // [analyticsApi.reducerPath]: analyticsApi.reducer,
  })(action.type === RESETTING_APP ? INITIAL_STATE : state, action);
