import React, { useEffect, useMemo, useState } from 'react';
import './index.scss';
import CombinationsCarousel from './Carousel';
import _chunk from 'lodash/chunk';
import { useViewId } from '../../selectors';
import { useCreativeAnalyticsGet } from '../../contexts';

const UntestedCombinations = ({ intelData, intelLoading, intelError }) => {
  const [combinationsData, setCombiniationsData] = useState([]);
  const [error, setError] = useState(false);
  const {
    getRecoRulesData,
    getRecoRulesError,
    getRecoRulesLoading,
    getRecoRulesRequest,
    deleteRecoRulesData,
    deleteRecoRulesLoading,
    deleteRecoRulesRequest,
    saveRecoRulesData,
    saveRecoRulesRequest,
  } = useCreativeAnalyticsGet();
  const view_id = useViewId();

  useEffect(() => {
    getRecoRulesRequest(view_id);
  }, []);

  const hiddenCategories = useMemo(() => {
      let categories = [];
  
      if (getRecoRulesData) {
        categories = getRecoRulesData.reduce((arr, curr) => {
          arr.push(curr.category_a);
          arr.push(curr.category_b);
  
          return arr.filter((c) => c);
        }, []);
      }

      return categories;
  }, [getRecoRulesData]);

  const hiddenLabels = useMemo(() => {
      let labels = [];
  
      if (getRecoRulesData) {
        labels = getRecoRulesData.reduce((arr, curr) => {
          arr.push(curr.label_a);
          arr.push(curr.label_b);
  
          return arr.filter((c) => c);
        }, []);
      }

      return labels;
  }, [getRecoRulesData]);

  const handleHideCategory = (category) => {
    const rules = getRecoRulesData || [];
    const rulesUpdate = [
      ...rules,
      {
        category_a: category,
        label_a: '',
        category_b: '',
        label_b: '',
        explanation: '',
      },
    ];

    saveRecoRulesRequest({
      view_id,
      rules: rulesUpdate,
    });
  };

  const handleHideLabel = (label) => {
    const rules = getRecoRulesData || [];
    const rulesUpdate = [
      ...rules,
      {
        category_a: '',
        label_a: label,
        category_b: '',
        label_b: '',
        explanation: '',
      },
    ];

    saveRecoRulesRequest({
      view_id,
      rules: rulesUpdate,
    });
  };

  useEffect(() => {
    if (saveRecoRulesData) {
      getRecoRulesRequest(view_id);
    }
  }, [saveRecoRulesData]);

  useEffect(() => {
    if (deleteRecoRulesData) {
      getRecoRulesRequest(view_id);
    }
  }, [deleteRecoRulesData]);

  const resetFilters = () => {
    if (getRecoRulesData) {
      const rule_ids = getRecoRulesData.map(({ rule_id }) => rule_id);

      deleteRecoRulesRequest({
        view_id,
        rule_ids,
      });
    }
  };

  useEffect(() => {
    if (intelData) {
      if (Array.isArray(intelData.combinations)) {
        setCombiniationsData(intelData.combinations);
        setError(false);
      } else {
        setError(true);
      }
    }
  }, [intelData]);

  const chunked = useMemo(() => {
    const combos = combinationsData?.filter((c) => {
      return (
        !hiddenCategories.some((category) =>
          [c.Category1, c.Category2].includes(category)
        ) && !hiddenLabels.some((label) => [c.Label1, c.Label2].includes(label))
      );
    });
    return _chunk(combos, 3);
  }, [combinationsData, hiddenCategories, hiddenLabels]);

  if (intelError) return <div className="p-2">There was an error.</div>;

  if (error)
    return <div className="p-2">No valid untested combinations found.</div>;

  if (intelLoading)
    return (
      <div className="m-5 d-flex justify-content-center w-100 text-center">
        <i className="fas fa-spinner fa-spin" style={{ fontSize: '1.2rem' }} />
      </div>
    );

  return (
    <div className="combinations">
      <CombinationsCarousel
        combinations={chunked}
        {...{
          resetFilters,
          handleHideCategory,
          handleHideLabel,
          getRecoRulesLoading,
          deleteRecoRulesLoading,
        }}
      />
    </div>
  );
};

export default UntestedCombinations;
