import {
  ALL_FEATURES,
  COPY_SUMMARY,
  DISPLAY_LOOKUP,
  GROUP_BY_AD_NAME,
  REPORT_CARD_TAB,
} from './constants';

const DISABLED_BY_DEFAULT = [GROUP_BY_AD_NAME, COPY_SUMMARY];

const setDefault = (feature) => {
  if (DISABLED_BY_DEFAULT.includes(feature)) return false;

  return true;
};
export const fillMissingPermissions = (permissions = {}) => {
  let result = {};

  ALL_FEATURES.forEach((feature) => {
    const match = permissions[feature] ?? setDefault(feature);
    result[feature] = match;
  });

  return result;
};

export const getDefaultPermissions = () => {
  return ALL_FEATURES.reduce((acc, feature) => {
    acc[feature] = true;
    return acc;
  }, {});
};
