import { useEffect, useRef, useState } from 'react';
import { useScrollIntoView } from '../../../../hooks/useScrollIntoView';

export const useOverrides = () => {
  const overridesRef = useRef(null);
  const [scrollToOverrides, setScrollToOverrides] = useState(false);
  useScrollIntoView(overridesRef, scrollToOverrides, {
    behavior: 'instant',
    block: 'center',
  });
  useEffect(() => {
    if (!overridesRef.current) return;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setScrollToOverrides(false); // When the element is visible, set scrollToOverrides to false
          }
        });
      },
      {
        root: null, // Observe in the viewport
        threshold: 0.1, // Trigger when 10% of the element is visible
      }
    );

    observer.observe(overridesRef.current);

    // Cleanup the observer when the component unmounts or ref changes
    return () => {
      if (overridesRef.current) observer.unobserve(overridesRef.current);
    };
  }, [overridesRef]);

  return { overridesRef, setScrollToOverrides };
};
