import React from 'react';
import Modal from '../Modal';
import Button from '../Button';
import Overlay from '../Overlay';

import './styles.scss';

type AlertModalProps = {};

export const AlertModal = ({
  onConfirm,
  show,
  modalStyle,
  modalClass,
  close,
  title,
  message,
  ...rest
}) => {
  return (
    <Overlay show={show} close={close} id>
      <Modal
        styles={modalStyle}
        className={`alert-modal ${modalClass}`}
        bottom={
          <div>
            <Button className="mr-2" appearance="subtle" onClick={close}>
              Cancel
            </Button>
            <Button onClick={onConfirm}>Continue</Button>
          </div>
        }
        {...rest}
      >
        <h2>{title}</h2>
        <p>{message}</p>
      </Modal>
    </Overlay>
  );
};
