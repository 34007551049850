import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LabelObject } from '../types';
import useDatePeriods from './useDatePeriods';
import { LAST_MONTH, toDarwinDate } from '../../../utils/darwin-dates';
import { useCreativeAnalyticsGet } from '../contexts';
import { delay } from '../../../utils';
import {
  setMetricsData,
  setMissingData,
  setPfrLoading,
} from '../../../store/CreativeAnalytics/creativeAnalyticsSlice';
import { endOfMonth, startOfMonth } from 'date-fns';
import { useCreativeAnalyticsStore } from '../../../selectors';
import { nanoid } from 'nanoid';

const HEADERS = { 'content-type': 'application/json' };

const triggerAsyncApi = (payload) => {
  fetch(
    [
      'api',
      'creative-reports',
      'performance-frequency',
      payload.view_id,
      'async',
      'get_perf_freq',
    ].join('/'),
    {
      method: 'POST',
      headers: HEADERS,
      credentials: 'include',
      body: JSON.stringify(payload),
    }
  )
    .then(() => console.log('Fetch triggered'))
    .catch((error) => console.error('Error triggering fetch:', error));
};

const pollStatus = async (
  payload,
  handleData,
  handleError,
  startLoading,
  stopLoading,
  handleMissingData,
  handleRetrievedDate,
  metricsData,
  instanceId
) => {
  let continuePolling = true;
  let errorCount = 0;

  const poll = async () => {
    if (!continuePolling) {
      return;
    }

    try {
      const response = await fetch(
        [
          'api',
          'creative-reports',
          'performance-frequency',
          payload.view_id,
          'check_status',
          'get_perf_freq',
        ].join('/'),
        {
          method: 'POST',
          headers: HEADERS,
          credentials: 'include',
          body: JSON.stringify(payload),
        }
      );

      const jsonData = await response.json();
      const data = jsonData?.data || jsonData;

      if (data.Message === 'Performance Frequency Data still being compiled.') {
        console.log(`Data loading. Polling instance ${instanceId}`);
        errorCount = 0; // Reset error count on success
        setTimeout(poll, 1000); // Retry in 1 second
      } else if (data) {
        const res = await handleDataResponse(data);

        if (data.retrieved_date) {
          handleRetrievedDate(data.retrieved_date);
        }

        if (payload?.metrics_only) {
          handleData(res?.metric_data);
        } else {
          if (res?.missing_data) {
            handleMissingData(res.missing_data);
          }
          if (res?.metric_data) {
            setMetricsData(res.metric_data);
          }
          handleData(res?.labels);
        }

        stopLoading();
        continuePolling = false; // End polling when data is fully retrieved
      } else {
        console.error('Unexpected data format:', data);
        handleError(data.error);
        stopLoading();
      }
    } catch (error) {
      errorCount += 1;
      console.error('Error:', error);
      handleError(error);

      if (errorCount < 5) {
        setTimeout(poll, 1000 * Math.pow(2, errorCount));
      } else {
        stopLoading();
        console.error('Max retry attempts reached. Stopping polling.');
      }
    }
  };

  startLoading();
  poll();

  return {
    abort: () => {
      console.log(`aborting instance ${instanceId}`);
      continuePolling = false;
    },
  };
};

export const useFetchPFRData = ({
  start_date,
  end_date,
  metrics_only = false,
  autoFetch = false,
  active_only,
  selectedUdcLabels = [],
  triggerStore = false,
}) => {
  const dispatch = useDispatch();
  const { view_id } = useSelector(({ ViewSettings: { viewId } }) => ({
    view_id: viewId,
  }));
  const { pfrLoading } = useCreativeAnalyticsStore();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [metricsData, setMetricsData] = useState([]);
  const [error, setError] = useState(null);
  const [retrievedDate, setRetrievedDate] = useState('');
  const cache = useRef(null);
  const [hasFetched, setHasFetched] = useState(false);

  const currentPollingControl = useRef(null);

  const env = window.location.href.includes('dashboard.darwinsoftware.io')
    ? 'prod'
    : 'dev';
  const makePayload = (viewId) => {
    return {
      view_id: viewId,
      start_date: start_date?.length
        ? start_date
        : toDarwinDate(startOfMonth(LAST_MONTH)),
      end_date: end_date?.length
        ? end_date
        : toDarwinDate(endOfMonth(LAST_MONTH)),
      metrics_only,
      active_only,
      ...(selectedUdcLabels.length && { udc_filters: selectedUdcLabels }),
      env,
    };
  };

  const handleMissingData = (missing_data) => {
    dispatch(setMissingData(missing_data));
  };

  const fetchData = async (payload, refresh) => {
    const { view_id } = payload;
    const instanceId = nanoid();

    if (currentPollingControl?.current?.abort) {
      currentPollingControl.current.abort();
    }

    if (!refresh) {
      const poll = async () => {
        // check if JSON file exists already
        try {
          const response = await fetch(
            [
              'api',
              'creative-reports',
              'performance-frequency',
              payload.view_id,
              'check_status',
              'get_perf_freq',
            ].join('/'),
            {
              method: 'POST',
              headers: HEADERS,
              credentials: 'include',
              body: JSON.stringify(payload),
            }
          );

          const jsonData = await response.json();
          const data = jsonData?.data || jsonData;

          if (
            data.Message === 'Performance Frequency Data still being compiled.'
          ) {
            console.log(`Data loading. Polling instance ${instanceId}`);

            setTimeout(poll, 1000);
          } else if (data && !data.error) {
            const res = await handleDataResponse(data);

            if (res?.metric_data) {
              setMetricsData(res.metric_data);
            }
            setData(res.labels);

            if (triggerStore) {
              dispatch(setPfrLoading(false));
            }
            setLoading(false);

            setRetrievedDate(data.retrieved_date);

            if (res?.missing_data) {
              handleMissingData(res.missing_data);
            }
            return;
          } else {
            console.error('Error:', data);

            if (triggerStore) {
              dispatch(setPfrLoading(false));
            }

            if (data.error.includes('No matching document found')) {
              // hard refresh if "no matching document" error
              console.log(
                'hard refreshing due to "no matching document" error'
              );
              fetchData(payload, true);
            } else {
              setLoading(false);
            }
          }
        } catch (e) {
          console.error(e);
          setError(e);

          if (triggerStore) {
            dispatch(setPfrLoading(false));
          }

          setLoading(false);
        }
      };

      if (triggerStore) {
        dispatch(setPfrLoading(true));
      }

      setLoading(true);

      poll();
    } else {
      try {
        triggerAsyncApi(payload);
        await delay(5000);
        currentPollingControl.current = await pollStatus(
          payload,
          (data) => setData(data),
          (error) => setError(error),
          () => {
            if (triggerStore) {
              dispatch(setPfrLoading(true));
            }

            setLoading(true);
          },
          () => {
            if (triggerStore) {
              dispatch(setPfrLoading(false));
            }

            setLoading(false);
            setHasFetched(true);
          },
          (missing_data) => handleMissingData(missing_data),
          (retrieved_date) => setRetrievedDate(retrieved_date),
          (metric_data) => setMetricsData(metric_data),
          instanceId
        );
      } catch (error) {
        console.error('Error fetching data for view:', view_id, error);
      }
    }
  };

  const request = (refresh) => {
    setHasFetched(false);

    if (triggerStore) {
      dispatch(setPfrLoading(true));
    }
    setLoading(true);

    setError(null);
    setData([]);
    const payload = makePayload(view_id);
    fetchData(payload, refresh);
  };

  useEffect(() => {
    return () => {
      if (currentPollingControl?.current?.abort) {
        currentPollingControl.current.abort();
      }
    };
  }, [start_date, end_date, view_id, selectedUdcLabels]);

  let isLoading = false;

  if (!triggerStore) {
    isLoading = loading;
  } else {
    isLoading = pfrLoading || (!data?.length && !hasFetched);
  }
  return {
    data,
    metricsData,
    loading: isLoading,
    error,
    request,
    retrievedDate,
  };
};

type DataResponseType = {
  labels: any[];
  metric_data?: any[];
  missing_data?: [];
};

const EMPTY_DATA: DataResponseType = {
  labels: [],
  metric_data: [],
};

export const handleDataResponse = async (
  data = null
): Promise<DataResponseType> => {
  if (!data) return EMPTY_DATA;

  if (data && data.Message) {
    const apiDataOrS3File = data.Message;

    if (
      typeof apiDataOrS3File === 'string' ||
      apiDataOrS3File instanceof String
    ) {
      return getDataFromS3Bucket(apiDataOrS3File);
    } else {
      if (Array.isArray(apiDataOrS3File)) {
        return { labels: apiDataOrS3File };
      }

      if (typeof apiDataOrS3File === 'object') {
        const {
          data: labels = [],
          metric_data,
        }: { data: LabelObject[]; metric_data: [] } = apiDataOrS3File;

        return {
          labels,
          metric_data,
        };
      }
    }
  }

  return EMPTY_DATA;
};

const getDataFromS3Bucket = async (apiDataOrS3File) => {
  const s3_url =
    'https://performance-frequency-payloads.s3.us-east-1.amazonaws.com/' +
    apiDataOrS3File +
    '.json?' +
    't=' +
    Date.now();

  const jsonData = await getJSON(s3_url)
    .then((json) => {
      return json;
    })
    .catch((error) => {
      console.error(error);

      return EMPTY_DATA;
    });

  return jsonData;
};

export const getJSON = (url): Promise<DataResponseType | null> => {
  return new Promise((resolve, reject) => {
    var xhr = new XMLHttpRequest();
    xhr.open('GET', url, true);
    xhr.onload = function () {
      var status = xhr.status;
      if (status === 200) {
        resolve(extractData(JSON.parse(xhr.response)));
      } else {
        console.log('Error pulling json');
        reject(extractData(null));
      }
    };
    xhr.send();
  });
};

const extractData = (data): DataResponseType => {
  if (!data) return EMPTY_DATA;

  try {
    if (Array.isArray(data)) {
      return { labels: [...data] };
    } else {
      const { data: labels, metric_data, missing_data } = data;
      return { labels, metric_data, missing_data };
    }
  } catch (e) {
    console.error(e);
    return EMPTY_DATA;
  }
};
