export const modifyLabel = (curr) => {
  let label = { ...curr };

  if (label.type === 'synonyms') {
    if (label.value.slice(0, 4) === 'CCC_') {
      label.name = label.value.split('_')[2];
      label.category = label.value.split('_')[1];
      label.synonym_type = 'CCC';
    }
    if (label.value.slice(0, 3) === 'DW-') {
      label.category = label.value.split('-')[1].trim();
      label.synonym_type = 'DW';
    }

    if (!label.category) label.category = 'Keyword';
  }
  if (label.type === 'custom_trained_label_settings') {
    label.category = 'Unique Client Attributes';
  }
  if (label.value === 'all_ads') label.category = 'All Ads';
  if (label.value === 'all_visuals' || label.value === 'new_visuals')
    label.category = 'All Visuals';

  if (label.value.includes('Top Pixel Color')) {
    label.category = 'Top Pixel Color';
  }

  if (label.value === 'CST_PROMO') {
    label.category = 'Promo / No Promo';
    label.name = 'Promo';
  }

  if (label.value === 'CST_NO_PROMO') {
    label.category = 'Promo / No Promo';
    label.name = 'No Promo';
  }

  if (label.value.includes('CSTPROMO')) {
    label.category = 'Promotion Type';
    label.name = label.value.split('_')[1];
  }

  if (label.value === 'CST_BRAND') {
    label.category = 'Brand / No Brand';
    label.name = 'Brand';
  }

  if (label.value === 'CST_NO_BRAND') {
    label.category = 'Brand / No Brand';
    label.name = 'No Brand';
  }

  if (label.type === 'Ad Name Label') {
    label.category = 'Ad Name Label';
  }

  if (
    (label.type !== 'synonyms' &&
      (label.type === 'client_custom_category' ||
        label.type === 'user_defined_labels' ||
        label.type === 'derived_label' ||
        label.type === 'Ad Name Label' ||
        label.type === 'ad_name_label' ||
        label.value.includes('CCC') ||
        label.value.includes('DW'))) ||
    label.value.includes('MM_')
  ) {
    label.ccc = true;
  }

  return label;
};
