import React, { createContext, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
const StaticReportContext = createContext(null);

export const StaticReportProvider = ({
  labelsLookup,
  visualsLookup,
  categoriesLookup,
  children,
}) => {
  return (
    <StaticReportContext.Provider
      value={{
        labelsLookup,
        visualsLookup,
        categoriesLookup,
      }}
    >
      {children}
    </StaticReportContext.Provider>
  );
};

export default StaticReportProvider;

export function useStaticReportContext() {
  return useContext(StaticReportContext);
}
