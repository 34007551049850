import React, { useState } from 'react';
import Overlay from '../../components/Overlay';
import Modal from '../../components/Modal';
import Button from '../../components/Button';
import { useCreativeAnalyticsGet } from '../CreativeAnalytics/contexts';
import { CategoriesSelectModal } from '../CreativeAnalytics/CategoriesSelectModal';
import { usePostRequest } from '../../components/Hooks/usePostRequest';

export const ShareReport = ({
  close,
  submit,
  sharedReportId,
  loading,
  categorySettings,
  location,
  sharePayloadError,
}) => {
  const [report_name, setName] = useState('');
  const [report_description, setDesc] = useState('');
  const [onlyIncludeSelectedCategory, setOnlyIncludeSelectedCategory] =
    useState(false);
  const { category } = useCreativeAnalyticsGet();
  const [showCategoriesSelect, setShowCategoriesSelect] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [elementsToInclude, setElementsToInclude] = useState({
    visuals: true,
    table: false,
  });

  const onSubmit = () => {
    submit({
      report_name,
      report_description,
      selectedCategories,
      elementsToInclude,
    });
  };

  const form = (
    <div className="p-1 w-100">
      <div className="form-group mb-5">
        <label>Report Name</label>
        <input
          className="w-100"
          onChange={(e) => setName(e.target.value)}
        ></input>
      </div>

      <div className="form-group mb-5">
        <label>Report Description</label>
        <textarea
          className="w-100"
          onChange={(e) => setDesc(e.target.value)}
        ></textarea>
      </div>

      {location === 'pfr' && (
        <div className="form-group d-flex mb-3">
          <button
            className={`category-select-toggle ${
              selectedCategories.length ? 'active' : ''
            }`}
            onClick={() => setShowCategoriesSelect(true)}
          >
            Only include selected categories{' '}
            {
              <input
                type="checkbox"
                checked={selectedCategories.length}
                style={{
                  flex: 'none',
                  height: '20px',
                  width: '20px',
                  marginLeft: '2rem',
                  pointerEvents: 'none',
                }}
              />
            }
          </button>
        </div>
      )}

      {location === 'dna' && (
        <>
          <div className="form-group d-flex mb-3">
            <label>Include Table</label>
            <input
              type="checkbox"
              checked={elementsToInclude.table}
              onChange={(e) =>
                setElementsToInclude((prev) => ({
                  ...prev,
                  table: e.target.checked,
                }))
              }
              style={{
                flex: 'none',
                height: '16px',
                width: '16px',
                marginLeft: '2rem',
              }}
            />
          </div>
        </>
      )}
    </div>
  );

  const { protocol, hostname, port } = window.location;
  const rootUrl = `${protocol}//${hostname}${port ? `:${port}` : ''}`;
  const url = `${rootUrl}/static-report${
    location === 'report_card' ? '-card' : ''
  }/${sharedReportId}`;

  const success = (
    <div>
      <h3>Report successfully created.</h3>
      <p>
        Click{' '}
        <a target="_blank" href={url} style={{ fontSize: '1.2rem' }}>
          here
        </a>{' '}
        to open and share.
      </p>
    </div>
  );

  return (
    <Overlay close={close} show>
      <Modal className="share-report-modal" close={close}>
        <h3 className="mb-5" style={{ fontSize: '1.4rem' }}>
          Create Static Report
        </h3>
        {showCategoriesSelect && (
          <CategoriesSelectModal
            show={showCategoriesSelect}
            setShow={setShowCategoriesSelect}
            existing={selectedCategories}
            onConfirm={(update) => setSelectedCategories(update)}
            categorySettings={categorySettings}
          />
        )}
        {!!sharedReportId ? success : form}
        {!!sharedReportId ? (
          <div className="align-self-end w-100">
            <Button fullWidth className="mt-3" onClick={close}>
              Confirm
            </Button>
          </div>
        ) : (
          <div className="align-self-end w-100">
            <Button
              fullWidth
              className="mt-3 mr-3"
              onClick={close}
              appearance="subtle"
            >
              Cancel
            </Button>
            <Button
              fullWidth
              className="mt-3"
              loading={loading}
              onClick={onSubmit}
            >
              Generate report
            </Button>
          </div>
        )}
        {sharePayloadError && (
          <div className="mt-4" style={{ color: '#333' }}>
            <i className="fa-solid fa-circle-exclamation mr-2" />
            Request contains too much data.
            <br />
            Please select fewer categories and try again.
          </div>
        )}
      </Modal>
    </Overlay>
  );
};
