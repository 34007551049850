import React from 'react';
import { VisualTable } from './VisualTable';
import { VisualsWrapper } from './VisualsWrapper';
import { DnaColumnPicker } from '../DnaColumnPicker';
import CarouselIcon from '../icons/CarouselIcon';
import StackedIcon from '../icons/StackedIcon';

export const CreativePerformance = ({
  data,
  columns,
  metadata,
  metadataFields,
  groupParams,
  formatMetric,
  columnOptions,
  handleCreateManualTag,
  SortToggle,
  ShareButton,
  stacked,
  setStacked,
  ColumnPicker,
  iterationsData,
  isOrganicView,
  metricsLookup,
}) => {
  return (
    <div className="creative-performance">
      <div className="d-flex justify-content-between">
        <div className="mb-5 d-flex">
          {ShareButton}
          <div className="ml-2">{ColumnPicker}</div>
        </div>
        {SortToggle}
      </div>

      <VisualsWrapper
        visuals={data}
        columns={columns}
        metadata={metadata}
        metadataFields={metadataFields}
        groupParams={groupParams}
        formatMetric={formatMetric}
        columnOptions={columnOptions}
        handleCreateManualTag={handleCreateManualTag}
        stacked={stacked}
        iterationsData={iterationsData}
        isOrganicView={isOrganicView}
        metricsLookup={metricsLookup}
      />
    </div>
  );
};
